import { 
    getLrPendingForGatePassHierarchical,
    getLrPendingForGatePass,
    getLrPendingForCashMemoGatePassHierarchical,
    getLrPendingForCashMemoGatePass,
    getLrPendingForBillCumGatePassHierarchical,
    getLrPendingForBillCumGatePass,
    getLhsPendingDDSettlementHierarchical,
    getLhsPendingDDSettlement,
    getLhsPendingPreDDHierarchical,
    getLhsPendingPreDD,
    getLhsPendingDDDToLocationHierarchical,
    getLhsPendingDDDToLocation,
    getLhsPendingDDDFromLocationHierarchical,
    getLhsPendingDDDFromLocation,
    getDeliverySummary,
    getdeliveryCommonFilter
} from "api";

export const getDeliverySummaryReq = async (body) => { 
    const response = await getDeliverySummary(body); 
    return response;
};
export const getdeliveryCommonFilterReq = async (body) => { 
    const response = await getdeliveryCommonFilter(body); 
    return response;
};

export const getLhsPendingDDDFromLocationReq = async (body) => { 
    const response = await getLhsPendingDDDFromLocation(body); 
    return response;
};

export const getLhsPendingDDDFromLocationHierarchicalReq = async (body) => { 
    const response = await getLhsPendingDDDFromLocationHierarchical(body); 
    return response;
};

export const getLhsPendingDDDToLocationReq = async (body) => { 
    const response = await getLhsPendingDDDToLocation(body); 
    return response;
};

export const getLhsPendingDDDToLocationHierarchicalReq = async (body) => { 
    const response = await getLhsPendingDDDToLocationHierarchical(body); 
    return response;
};

export const getLhsPendingPreDDReq = async (body) => { 
    const response = await getLhsPendingPreDD(body); 
    return response;
};

export const getLhsPendingPreDDHierarchicalReq = async (body) => { 
    const response = await getLhsPendingPreDDHierarchical(body); 
    return response;
};

export const getLhsPendingDDSettlementReq = async (body) => { 
    const response = await getLhsPendingDDSettlement(body); 
    return response;
};

export const getLhsPendingDDSettlementHierarchicalReq = async (body) => { 
    const response = await getLhsPendingDDSettlementHierarchical(body); 
    return response;
};

export const getLrPendingForBillCumGatePassReq = async (body) => { 
    const response = await getLrPendingForBillCumGatePass(body); 
    return response;
};

export const getLrPendingForBillCumGatePassHierarchicalReq = async (body) => { 
    const response = await getLrPendingForBillCumGatePassHierarchical(body); 
    return response;
};

export const getLrPendingForCashMemoGatePassReq = async (body) => { 
    const response = await getLrPendingForCashMemoGatePass(body); 
    return response;
};

export const getLrPendingForCashMemoGatePassHierarchicalReq = async (body) => { 
    const response = await getLrPendingForCashMemoGatePassHierarchical(body); 
    return response;
};

export const getLrPendingForGatePassReq = async (body) => { 
    const response = await getLrPendingForGatePass(body); 
    return response;
};

export const getLrPendingForGatePassHierarchicalReq = async (body) => { 
    const response = await getLrPendingForGatePassHierarchical(body); 
    return response;
};
