import { 
    getFtlBillingWise,
    getFtlBillingWiseHierarchical,
    getPodSummary,
    getFtlMarketingExecutiveHierarchical,
    getFtlMarketingExecutive,
    getFtlTrafficInchargeHierarchical,
    getFtlTrafficIncharge,
    getSundryMarketingExecutiveHierarchical,
    getSundryMarketingExecutive,
    getSundryWiseHierarchical,
    getSundryWise,
    getPodCommonFilter,
} from "api";

export const getPodSummaryReq = async (body) => { 
    const response = await getPodSummary(body); 
    return response;
};
export const getPodCommonFilterReq = async (body) => { 
    const response = await getPodCommonFilter(body); 
    return response;
};

export const getFtlBillingWiseHierarchicalReq = async (body) => { 
    const response = await getFtlBillingWiseHierarchical(body); 
    return response;
};

export const getFtlBillingWiseReq = async (body) => { 
    const response = await getFtlBillingWise(body); 
    return response;
};

export const getFtlMarketingExecutiveHierarchicalReq = async (body) => { 
    const response = await getFtlMarketingExecutiveHierarchical(body); 
    return response;
};

export const getFtlMarketingExecutiveReq = async (body) => { 
    const response = await getFtlMarketingExecutive(body); 
    return response;
};

export const getFtlTrafficInchargeHierarchicalReq = async (body) => { 
    const response = await getFtlTrafficInchargeHierarchical(body); 
    return response;
};

export const getFtlTrafficInchargeReq = async (body) => { 
    const response = await getFtlTrafficIncharge(body); 
    return response;
};

export const getSundryMarketingExecutiveHierarchicalReq = async (body) => { 
    const response = await getSundryMarketingExecutiveHierarchical(body); 
    return response;
};

export const getSundryMarketingExecutiveReq = async (body) => { 
    const response = await getSundryMarketingExecutive(body); 
    return response;
};

export const getSundryWiseHierarchicalReq = async (body) => { 
    const response = await getSundryWiseHierarchical(body); 
    return response;
};

export const getSundryWiseReq = async (body) => { 
    const response = await getSundryWise(body); 
    return response;
};
