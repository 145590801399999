import React , {useEffect,useState,useRef} from "react"
import { getPoDUploadedButMRPendingReq, getpodUploadedMrPendingReq } from "./billingService";
import { connect } from "react-redux";
import DrillDownTable from "components/CustomComponents/DrillDownTable";
import { Card } from "reactstrap";
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import generatePDF from "react-to-pdf";
import { options } from "utils/pdfOptions";
import {DropdownItem,DropdownMenu,DropdownToggle,ButtonDropdown} from "reactstrap";
import { useLocation } from 'react-router-dom';
import * as XLSX from 'xlsx';
import BillingFilterBar from "./BillingFilterBar";

const PoDUploadedButMRPending = (props) => {
    const location = useLocation();
    const [shareBtnToggle, setshareBtnToggle] = useState(false);
    const [dwnBtnToggle, setdwnBtnToggle] = useState(false);
    const breadcrumbs = location.state?.breadcrumbsState || [{ label: 'Home', field: '' }];
    const color = location.state?.color || "#F9E6C2";
    const queryParams = location.state?.queryParams || {};
    const tableRef = useRef(null);
    const [filters, setFilters] = useState({ ...queryParams });
    document.title = "Aurum | Dashboard";


  const breadcrumbItems = [
    { title: "Home", link: "/dashboard" },
    { title: "Billing", link: "/billing" },
    { title: "PoD Uploaded But MR Pending", link: "#" }
  ]

  useEffect(() => {
    props.setBreadcrumbItems('PoD Uploaded But MR Pending' , breadcrumbItems)
  },)

  const getTargetElement = () => document.getElementById("pdf-container");

  const downloadPDF = () => generatePDF(getTargetElement, options);
  const downloadExcel = () => {
    const wb = XLSX.utils.book_new();

    if (!tableRef.current || tableRef.current.rows.length === 0) {
      console.error('No table reference available to export.');
      return;
    }

    const ws = XLSX.utils.table_to_sheet(tableRef.current);
    XLSX.utils.book_append_sheet(wb, ws, "PoD Uploaded But MR Pending"); // Use document title for the sheet name

    if (wb.SheetNames.length === 0) {
      console.error('No data to export; workbook is empty.');
      return;
    }

    XLSX.writeFile(wb, "PoD Uploaded But MR Pending.xlsx"); // Use document title for the file name
  };

  const applyFilters = (filters) => {
    setFilters(filters || {});
  };

  return (
    <React.Fragment>
        <div style={{ position: "relative" }}>
            <div
            style={{
                position: "absolute",
                top: -50,
                right: 10,
                display: "flex",
            }}
            >
              <ButtonDropdown
                isOpen={dwnBtnToggle}
                toggle={() => {
                  setdwnBtnToggle(!dwnBtnToggle);
                }}
              >
                <DropdownToggle caret className="btn btn-primary w-m mx-2">
                  <i className="fas fa-download me-2"></i> Download <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={downloadPDF}>
                    <i className="fas fa-file-pdf me-2"></i> PDF
                  </DropdownItem>
                  <DropdownItem onClick={downloadExcel}>
                    <i className="fas fa-file-excel me-2"></i> Excel
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
              <ButtonDropdown
                  isOpen={shareBtnToggle}
                  toggle={() => {
                      setshareBtnToggle(!shareBtnToggle)
                  }}
              >
                  <DropdownToggle caret className="btn btn-primary w-m mx-2 ">
                  <i className="fas fa-share me-2"></i> Share Via <i className="mdi mdi-chevron-down"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                      <DropdownItem>Email</DropdownItem>
                      <DropdownItem>Whatsapp</DropdownItem>
                  </DropdownMenu>
              </ButtonDropdown>
            </div>
        </div>
        <BillingFilterBar onApplyFilters={applyFilters} filters={filters}/>
        <Card body id="pdf-container" className="overflow-hidden">
          <DrillDownTable 
            ref={tableRef}
            fetchDataFn={getPoDUploadedButMRPendingReq} 
            fetchDataAtBreadcrumbLimit={getpodUploadedMrPendingReq} 
            initialBreadcrumbs={breadcrumbs} 
            color={color}
            initialQueryParams={filters}
          />
      </Card>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(PoDUploadedButMRPending);