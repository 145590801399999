import React, { useRef, useEffect, useCallback, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { capitalizeWords } from 'utils';

const EnhancedTable = ({ fetchDataFn, customColumnDefs = [] }) => {
  const gridRef = useRef(null);
  const navigate = useNavigate();
  const paginationPageSizeSelector = [5, 10, 15];
  const [paginationPageSize, setPaginationPageSize] = useState(10);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  const columnDefs = customColumnDefs.length > 0 
    ? customColumnDefs 
    : columns.map(col => ({
        headerName: capitalizeWords(col.replace(/_/g, ' ')),
        field: col,
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
      }));

      const autoSizeStrategy = {
        type: 'fitCellContents'
      };

  const onPaginationChanged = useCallback((event) => {
    let pageSize = gridRef.current.api.paginationGetPageSize();
    setPaginationPageSize(pageSize);
  }, []);

  const onGridReady = (params) => {
    gridRef.current = params;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchDataFn();
        if (response.results.length > 0) {
          if (customColumnDefs.length === 0) {
            const firstItem = response.results[0];
            setColumns(Object.keys(firstItem)); // Set columns based on keys of the first item
            console.log(columns);
          }
          setData(response.results);
        } else {
          setData([]);
          setColumns([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setData([]);
        setColumns([]);
      }
    };

    fetchData();
  }, [fetchDataFn, customColumnDefs]);

  const onRowClicked = (event) => {
    const selectedCustomer = event.data.party_name;
    navigate('/customer-bills', { state: { selectedCustomer } });
  };



  return (
    <div style={{ padding: '8px' }}>
      <div className="ag-theme-quartz" style={{ width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={data}
          columnDefs={columnDefs}
          pagination
          onPaginationChanged={onPaginationChanged}
          paginationPageSize={paginationPageSize}
          paginationPageSizeSelector={paginationPageSizeSelector}
          defaultColDef={{
            filter: true,
            sortable: true,
            resizable: true,
            floatingFilter: false,
          }}
          onGridReady={onGridReady}
          domLayout='autoHeight'
          onRowClicked={onRowClicked}
          autoSizeStrategy={autoSizeStrategy}
        />
      </div>
    </div>
  );
};

export default EnhancedTable;
