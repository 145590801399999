import React, { useEffect, useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { Card } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { getagingPartyWiseReq } from './CreditControlService'; // Import your fetch function
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";

const CustomerBillsPending = (props) => {
  document.title = 'Aurum | Customer Bills Pending';
  const location = useLocation();
  const selectedCustomer = location.state?.selectedCustomer;

  const [data, setData] = useState([]);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [paginationPageSize, setPaginationPageSize] = useState(10);
  const gridRef = useRef(null);

  const breadcrumbItems = [
    { title: "Home", link: "/dashboard" },
    { title: "Credit", link: "/credit-control" },
    { title: selectedCustomer || "Customer Details", link: "#" }
  ];

  useEffect(() => {
    props.setBreadcrumbItems('Customer Details', breadcrumbItems);
  }, [selectedCustomer, props, breadcrumbItems]);

  const fetchData = async () => {
    if (!selectedCustomer) {
      console.error('No selected customer provided.');
      return;
    }

    try {
      const response = await getagingPartyWiseReq({ party_name__in: selectedCustomer });

      if (response?.results && Array.isArray(response.results)) {
        const responseData = response.results;

        if (responseData.length > 0) {
          const firstItem = responseData[0];
          setCustomerDetails(firstItem); // Set customer details from the first result
          setData(responseData);
        } else {
          console.warn('No data found in the response.');
          setData([]);
        }
      } else {
        console.warn('Response result is not an array.');
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error.message || 'Unknown error');
      setData([]);
    }
  };

  const autoSizeAllColumns = () => {
    const allColumnIds = [];
    const columnApi = gridRef.current?.columnApi;
    if (columnApi) {
      columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      columnApi.autoSizeColumns(allColumnIds, false);
    }
  };

  const onGridReady = (params) => {
    gridRef.current = params;
    autoSizeAllColumns();
  };

  // Define a function to determine row style based on conditions
  const getRowStyle = params => {
    if (params.data.days_0_30 > 0) {
      return {
        backgroundColor: '#C8E6C9' // Muted light green background
      };
    }
    if (params.data.days_31_60 > 0) {
      return {
        backgroundColor: '#EFEBE9' // Muted light brown background
      };
    }
    if (params.data.days_61_90 > 0) {
      return {
        backgroundColor: '#FFE0B2' // Muted light orange background
      };
    }
    if (params.data.days_91_180 > 0) {
      return {
        backgroundColor: '#F8BBD0' // Muted pink background
      };
    }
    if (params.data.days_over_181 > 0) {
      return {
        backgroundColor: '#FFCDD2' // Dark red background
      };
    }
    return null; // Default style if condition is not met
  };

  const gridOptions = {
    columnDefs: columnDef,
    defaultColDef: {
      resizable: true
    },
    getRowStyle: getRowStyle
  };

  const onPaginationChanged = () => {
    const pageSize = gridRef.current?.api.paginationGetPageSize();
    setPaginationPageSize(pageSize);
  };

  useEffect(() => {
    fetchData();
  }, [selectedCustomer]);

  useEffect(() => {
    if (gridRef.current) {
      autoSizeAllColumns();
    }
  }, [data]);

  return (
    <React.Fragment>
      <Card body id="pdf-container" className="overflow-hidden">
        {customerDetails && (
          <div style={{ marginBottom: '20px', padding: '16px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
            <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Customer Details</h2>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div>
                <strong>Customer Name: </strong> {customerDetails.party_name} <br />
                <strong>Ledger: </strong> {customerDetails.ledger} <br />
                <strong>Marketing Executive: </strong> {customerDetails.marketing_executive !== 'nan' ? customerDetails.marketing_executive : 'N/A'} <br />
              </div>
              <div>
                <strong>Collection Executive: </strong> {customerDetails.collection_executive !== 'nan' ? customerDetails.collection_executive : 'N/A'} <br />
                <strong>Mobile No: </strong> {customerDetails.mobile_no || 'N/A'} <br />
                <strong>Email Address: </strong> {customerDetails.email_address !== 'nan' ? customerDetails.email_address : 'N/A'} <br />
              </div>
            </div>
          </div>
        )}
        <div className="ag-theme-quartz" style={{ width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            rowData={data}
            columnDefs={columnDef} // Use the predefined columnDef
            pagination
            paginationPageSize={paginationPageSize}
            defaultColDef={{
              filter: true,
              sortable: true,
              resizable: true,
              floatingFilter: false,
            }}
            getRowStyle={getRowStyle}
            onGridReady={onGridReady}
            onPaginationChanged={onPaginationChanged}
            domLayout="autoHeight"
          />
        </div>
      </Card>
    </React.Fragment>
  );
};

// Utility function to capitalize words
const capitalizeWords = (str) => str.replace(/\b\w/g, char => char.toUpperCase());

const columnDef = [
  { headerName: 'Party Name', field: 'party_name', sortable: true, filter: true, suppressMenu: true, width: 275 },
  { headerName: 'Document No', field: 'document_no', sortable: true, filter: true, suppressMenu: true, width: 170 },
  { headerName: 'Bill Date', field: 'bill_date', sortable: true, filter: true, suppressMenu: true, width: 130 },
  { headerName: 'Due Date', field: 'due_date', sortable: true, filter: true, suppressMenu: true, width: 130 },
  { headerName: 'Bill Amount', field: 'bill_amount', sortable: true, filter: true, suppressMenu: true, width: 130 },
  { headerName: 'Balance Amount', field: 'balance_amount', sortable: true, filter: true, suppressMenu: true, width: 160 },
  { headerName: 'On Account Amount', field: 'on_account_amount', sortable: true, filter: true, suppressMenu: true, width: 180 },
  { headerName: 'Due Amount', field: 'due_amount', sortable: true, filter: true, suppressMenu: true, width: 140 },
  { headerName: 'Overdue Days', field: 'overdue_days', sortable: true, filter: true, suppressMenu: true, width: 150 },
  {
    headerName: '0-30 Days',
    field: 'days_0_30',
    sortable: true,
    filter: true,
    suppressMenu: true,
    width: 120,
  },
  {
    headerName: '31-60 Days',
    field: 'days_31_60',
    sortable: true,
    filter: true,
    suppressMenu: true,
    width: 120,
  },
  {
    headerName: '61-90 Days',
    field: 'days_61_90',
    sortable: true,
    filter: true,
    suppressMenu: true,
    width: 120,
  },
  {
    headerName: '91-180 Days',
    field: 'days_91_180',
    sortable: true,
    filter: true,
    suppressMenu: true,
    width: 130,
  },
  {
    headerName: '180+ Days',
    field: 'days_over_181',
    sortable: true,
    filter: true,
    suppressMenu: true,
    width: 120,
  },
];

export default connect(null, { setBreadcrumbItems })(CustomerBillsPending);
