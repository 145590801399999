import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import '../../components/CommonForBoth/rightbar.scss';
import { Link } from "react-router-dom";
import Select from "react-select";
import { Label, Badge } from "reactstrap";
import { getbillingCommonFilterReq } from './billingService';

const BillingFilterBar = ({ onApplyFilters, filters }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    load_type: filters?.load_type || [],
    billing_location: filters?.billing_location || [],
    payment_type: filters?.payment_type || [],
    customer_name: filters?.customer_name || [],
    marketer: filters?.marketer || []
  });

  const [bookingTypes, setBookingTypes] = useState([]);
  const [billingLocations, setBillingLocations] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [customerNames, setCustomerNames] = useState([]);
  const [marketers, setMarketers] = useState([]);
  const [appliedFilterCount, setAppliedFilterCount] = useState(0);

  const clearFilters = () => {
    setSelectedFilters({
      load_type: [],
      billing_location: [],
      payment_type: [],
      customer_name: [],
      marketer: []
    });
    onApplyFilters({});
    setAppliedFilterCount(0);
    setIsOpen(false);
  };

  const toggleFilterBar = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterChange = (name, selectedOptions) => {
    setSelectedFilters(prevState => ({
      ...prevState,
      [name]: selectedOptions || []
    }));
  };

  const applyFilters = () => {
    const filteredFilters = Object.fromEntries(
      Object.entries(selectedFilters)
        .filter(([_, value]) => value.length > 0)
        .map(([key, values]) => [
          key+"__in",
          values.map(option => option.value).join(',')
        ])
    );
    onApplyFilters(filteredFilters);
    setAppliedFilterCount(Object.values(selectedFilters).filter(filter => filter.length > 0).length);
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await getbillingCommonFilterReq();
        setBookingTypes(response?.results?.load_type || []);
        setBillingLocations(response?.results?.billing_location || []);
        setPaymentTypes(response?.results?.payment_type || []);
        setCustomerNames(response?.results?.customer_name || []);
        setMarketers(response?.results?.marketer || []);
      } catch (error) {
        console.error('Error fetching filters:', error);
      }
    };
    fetchFilters();
  }, []);

  return (
    <div>
      {!isOpen && (
        <button
          className="btn btn-primary rounded-circle position-fixed"
          style={{ bottom: '25px', right: '20px', zIndex: 9999, width: '60px', height: '60px', fontSize: '24px', position: 'relative' }}
          onClick={toggleFilterBar}
        >
          <i className="mdi mdi-filter-variant" />
          {appliedFilterCount > 0 && (
            <Badge
              style={{
                position: 'absolute',
                top: '-10px', // Places the badge above the button
                right: '-10px', // Adjusts the badge to the top-right
                zIndex: 10000,
                width: '25px',
                height: '25px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px',
              }}
              color="danger"
              pill
            >
              {appliedFilterCount}
            </Badge>
          )}

        </button>
      )}
      <div
        className={`offcanvas offcanvas-end filter-sidebar ${isOpen ? 'show' : ''}`}
        style={{
          visibility: isOpen ? 'visible' : 'hidden',
          width: isOpen ? '300px' : '0px',
          transition: 'width 0.3s ease',
          zIndex: 9999,
        }}
      >
        <div>
          <SimpleBar style={{ height: '100vh' }}>
            <div data-simplebar className="h-100">
              <div className="rightbar-title px-3 py-4">
                <Link
                  to="#"
                  onClick={e => {
                    e.preventDefault();
                    toggleFilterBar();
                  }}
                  className="right-bar-toggle float-end"
                >
                  <i className="mdi mdi-close noti-icon" />
                </Link>
                <h5 className="offcanvas-title">Filters</h5>
              </div>
              <hr className="my-0" />
              <div className="offcanvas-body" style={{
                height: '100vh' // Ensures full height
              }}>

                {/* Load Type Filter */}
                <div className="mb-3">
                  <Label>Load Type</Label>
                  <Select
                    value={selectedFilters.load_type}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('load_type', selectedOptions)}
                    options={bookingTypes.map(type => ({ value: type, label: type }))}
                    classNamePrefix="select2-selection"
                    isLoading={!bookingTypes.length}
                  />
                </div>

                {/* Billing Location Filter */}
                <div className="mb-3">
                  <Label>Billing Location</Label>
                  <Select
                    value={selectedFilters.billing_location}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('billing_location', selectedOptions)}
                    options={billingLocations.map(location => ({ value: location, label: location }))}
                    classNamePrefix="select2-selection"
                    isLoading={!billingLocations.length}
                  />
                </div>

                {/* Payment Type Filter */}
                <div className="mb-3">
                  <Label>Payment Type</Label>
                  <Select
                    value={selectedFilters.payment_type}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('payment_type', selectedOptions)}
                    options={paymentTypes.map(type => ({ value: type, label: type }))}
                    classNamePrefix="select2-selection"
                    isLoading={!paymentTypes.length}
                  />
                </div>

                {/* Customer Name Filter */}
                <div className="mb-3">
                  <Label>Customer Name</Label>
                  <Select
                    value={selectedFilters.customer_name}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('customer_name', selectedOptions)}
                    options={customerNames.map(name => ({ value: name, label: name }))}
                    classNamePrefix="select2-selection"
                    isLoading={!customerNames.length}
                  />
                </div>

                {/* Marketer Filter */}
                <div className="mb-3">
                  <Label>Marketer</Label>
                  <Select
                    value={selectedFilters.marketer}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('marketer', selectedOptions)}
                    options={marketers.map(mark => ({ value: mark, label: mark }))}
                    classNamePrefix="select2-selection"
                    isLoading={!marketers.length}
                  />
                </div>

                <div className="d-flex justify-content-around">
                  <button className="btn btn-secondary" onClick={clearFilters}>
                    Clear All Filters
                  </button>
                  <button className="btn btn-primary" onClick={applyFilters}>
                    Apply Filters
                  </button>
                </div>
              </div>
            </div>
          </SimpleBar>
        </div>
      </div>
      {
        isOpen && (
          <div
            className="rightbar-overlay"
            onClick={toggleFilterBar}
          />
        )
      }
    </div >
  );
};

BillingFilterBar.propTypes = {
  onApplyFilters: PropTypes.func.isRequired,
};

export default BillingFilterBar;
