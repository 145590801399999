import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import '../../components/CommonForBoth/rightbar.scss';
import { Link } from "react-router-dom";
import Select from "react-select";
import { Label, Badge } from "reactstrap";
import { getPodCommonFilterReq } from './podService';

const PodFilterBar = ({ onApplyFilters, filters }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    delivery_branch: filters?.delivery_branch || [],
    billing_branch: filters?.billing_branch || [],
    lr_date: filters?.lr_date || [],
    payment_type: filters?.payment_type || [],
    billing_client: filters?.billing_client || [],
    traffic_in_charge: filters?.traffic_in_charge || [],
    marketing_exec: filters?.marketing_exec || []
  });

  const [deliveryBranches, setDeliveryBranches] = useState([]);
  const [billingBranches, setBillingBranches] = useState([]);
  const [lrDates, setLrDates] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [billingClients, setBillingClients] = useState([]);
  const [trafficInCharges, setTrafficInCharges] = useState([]);
  const [marketingExecs, setMarketingExecs] = useState([]);
  const [appliedFilterCount, setAppliedFilterCount] = useState(0);

  const clearFilters = () => {
    setSelectedFilters({
      delivery_branch: [],
      billing_branch: [],
      lr_date: [],
      payment_type: [],
      billing_client: [],
      traffic_in_charge: [],
      marketing_exec: []
    });
    onApplyFilters({});
    setAppliedFilterCount(0);
    setIsOpen(false);
  };

  const toggleFilterBar = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterChange = (name, selectedOptions) => {
    setSelectedFilters(prevState => ({
      ...prevState,
      [name]: selectedOptions || []
    }));
  };

  const applyFilters = () => {
    const filteredFilters = Object.fromEntries(
      Object.entries(selectedFilters)
        .filter(([_, value]) => value.length > 0)
        .map(([key, values]) => [
          key+"__in",
          values.map(option => option.value).join(',')
        ])
    );
    onApplyFilters(filteredFilters);
    setAppliedFilterCount(Object.values(selectedFilters).filter(filter => filter.length > 0).length);
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await getPodCommonFilterReq();
        setDeliveryBranches(response?.results?.delivery_branch || []);
        setBillingBranches(response?.results?.billing_branch || []);
        setLrDates(response?.results?.lr_date || []);
        setPaymentTypes(response?.results?.payment_type || []);
        setBillingClients(response?.results?.billing_client || []);
        setTrafficInCharges(response?.results?.traffic_incharge || []);
        setMarketingExecs(response?.results?.marketing_exec || []);
      } catch (error) {
        console.error('Error fetching filters:', error);
      }
    };
    fetchFilters();
  }, []);

  return (
    <div>
      {!isOpen && (
        <button
          className="btn btn-primary rounded-circle position-fixed"
          style={{ bottom: '25px', right: '20px', zIndex: 9999, width: '60px', height: '60px', fontSize: '24px', position: 'relative' }}
          onClick={toggleFilterBar}
        >
          <i className="mdi mdi-filter-variant" />
          {appliedFilterCount > 0 && (
            <Badge
              style={{
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                zIndex: 10000,
                width: '25px',
                height: '25px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px',
              }}
              color="danger"
              pill
            >
              {appliedFilterCount}
            </Badge>
          )}

        </button>
      )}
      <div
        className={`offcanvas offcanvas-end filter-sidebar ${isOpen ? 'show' : ''}`}
        style={{
          visibility: isOpen ? 'visible' : 'hidden',
          width: isOpen ? '300px' : '0px',
          transition: 'width 0.3s ease',
          zIndex: 9999,
        }}
      >
        <div>
          <SimpleBar style={{ height: '100vh' }}>
            <div data-simplebar className="h-100">
              <div className="rightbar-title px-3 py-4">
                <Link
                  to="#"
                  onClick={e => {
                    e.preventDefault();
                    toggleFilterBar();
                  }}
                  className="right-bar-toggle float-end"
                >
                  <i className="mdi mdi-close noti-icon" />
                </Link>
                <h5 className="offcanvas-title">Filters</h5>
              </div>
              <hr className="my-0" />
              <div className="offcanvas-body" style={{
                height: '100vh'
              }}>

                {/* Delivery Branch Filter */}
                <div className="mb-3">
                  <Label>Delivery Branch</Label>
                  <Select
                    value={selectedFilters.delivery_branch}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('delivery_branch', selectedOptions)}
                    options={deliveryBranches.map(branch => ({ value: branch, label: branch }))}
                    classNamePrefix="select2-selection"
                    isLoading={!deliveryBranches.length}
                  />
                </div>

                {/* Billing Branch Filter */}
                <div className="mb-3">
                  <Label>Billing Branch</Label>
                  <Select
                    value={selectedFilters.billing_branch}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('billing_branch', selectedOptions)}
                    options={billingBranches.map(branch => ({ value: branch, label: branch }))}
                    classNamePrefix="select2-selection"
                    isLoading={!billingBranches.length}
                  />
                </div>

                {/* LR Date Filter */}
                <div className="mb-3">
                  <Label>LR Date</Label>
                  <Select
                    value={selectedFilters.lr_date}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('lr_date', selectedOptions)}
                    options={lrDates.map(date => ({ value: date, label: date }))}
                    classNamePrefix="select2-selection"
                    isLoading={!lrDates.length}
                  />
                </div>

                {/* Payment Type Filter */}
                <div className="mb-3">
                  <Label>Payment Type</Label>
                  <Select
                    value={selectedFilters.payment_type}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('payment_type', selectedOptions)}
                    options={paymentTypes.map(type => ({ value: type, label: type }))}
                    classNamePrefix="select2-selection"
                    isLoading={!paymentTypes.length}
                  />
                </div>

                {/* Billing Client Filter */}
                <div className="mb-3">
                  <Label>Billing Client</Label>
                  <Select
                    value={selectedFilters.billing_client}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('billing_client', selectedOptions)}
                    options={billingClients.map(client => ({ value: client, label: client }))}
                    classNamePrefix="select2-selection"
                    isLoading={!billingClients.length}
                  />
                </div>

                {/* Traffic In Charge Filter */}
                <div className="mb-3">
                  <Label>Traffic In Charge</Label>
                  <Select
                    value={selectedFilters.traffic_in_charge}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('traffic_in_charge', selectedOptions)}
                    options={trafficInCharges.map(inCharge => ({ value: inCharge, label: inCharge }))}
                    classNamePrefix="select2-selection"
                    isLoading={!trafficInCharges.length}
                  />
                </div>

                {/* Marketing Exec Filter */}
                <div className="mb-3">
                  <Label>Marketing Exec</Label>
                  <Select
                    value={selectedFilters.marketing_exec}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('marketing_exec', selectedOptions)}
                    options={marketingExecs.map(exec => ({ value: exec, label: exec }))}
                    classNamePrefix="select2-selection"
                    isLoading={!marketingExecs.length}
                  />
                </div>

                <div className="d-flex justify-content-around">
                  <button className="btn btn-secondary" onClick={clearFilters}>
                    Clear All Filters
                  </button>
                  <button className="btn btn-primary" onClick={applyFilters}>
                    Apply Filters
                  </button>
                </div>
              </div>
            </div>
          </SimpleBar>
        </div>
      </div>
      {
        isOpen && (
          <div
            className="rightbar-overlay"
            onClick={toggleFilterBar}
          />
        )
      }
    </div >
  );
};

PodFilterBar.propTypes = {
  onApplyFilters: PropTypes.func.isRequired,
};

export default PodFilterBar;
