import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';

const AverageCreditDaysChart = ({ fetchDataFn }) => {
  const [chartData, setChartData] = useState({ categories: [], series: [] });
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetchDataFn();
        const branches = response?.results?.branch_credit_average_time;

        const topBranches = branches
          .filter(branch => branch.avg_credit_time_days > 0)
          .sort((a, b) => b.avg_credit_time_days - a.avg_credit_time_days)
          .slice(0, 25);

        const categories = topBranches.map(item => item.branch.trim());
        const seriesData = topBranches.map(item => item.avg_credit_time_days);

        setChartData({
          categories,
          series: [{ name: 'Average Credit Days', data: seriesData }],
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    getData();
  }, [fetchDataFn]);

  const options = {
    chart: {
      type: 'bar',
      toolbar: {
        tools: {
          download: false,
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '70%', // Adjusted bar height for better visibility
        colors: {
          ranges: [
            {
              from: 0,
              to: 20,
              color: '#66BB6A' // Green for shorter credit days
            },
            {
              from: 21,
              to: 40,
              color: '#FFA726' // Orange for moderate credit days
            },
            {
              from: 41,
              to: 60,
              color: '#EF5350' // Red for longer credit days
            }
          ]
        }
      }
    },
    colors: ['#29B6F6'], // Default bar color if no specific range applies
    // dataLabels: {
    //   enabled: true,
    //   formatter: (val, { seriesIndex, dataPointIndex }) => {
    //     const branchName = chartData.categories[dataPointIndex];
    //     return `${branchName}: ${val} days`;
    //   },
    // },
    xaxis: {
      title: {
        text: 'Average Credit Days'
      },
    },
    yaxis: {
      categories: chartData.categories,
      show: false,
      title: {
        text: 'Branch Name'
      },
    },
    tooltip: {
      y: {
        formatter: (value, { seriesIndex, dataPointIndex, w }) => {
          const branchName = chartData.categories[dataPointIndex];
          return `${branchName}: ${value} days`;
        }
      },
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        const branchName = chartData.categories[dataPointIndex];
        const value = series[seriesIndex][dataPointIndex];
  
        return `
          <div style="
            background-color: #1E1E2F; 
            color: white; 
            border-radius: 8px; 
            padding: 10px; 
            font-family: Arial, sans-serif; 
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
            max-width: 400px;
          ">
            <div style="font-weight: bold; font-size: 14px;">${branchName}</div>
            <div style="font-size: 12px; color: #ccc; margin-top: 5px;">
              Avg Credit Days: <span style="color: #fff;">${value}</span>
            </div>
          </div>
        `;
      },
      position: function(data, { seriesIndex, dataPointIndex, w }) {
        return {
          x: w.globals.svgWidth - 120,
          y: data.globals.clientY
        };
      },
      style: {
        fontSize: '14px',
        fontFamily: 'Arial, sans-serif',
      }
    }
  };
  

  return (
    <div>
      <Chart options={options} series={chartData.series} type="bar" height={500} />
    </div>
  );
};

export default AverageCreditDaysChart;
