import PropTypes from 'prop-types'
import React, { useState } from "react"

import { connect } from "react-redux"

import { Link, useNavigate } from "react-router-dom"

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

// import megamenuImg from "../../assets/images/megamenu-img.png"
import logo from "../../assets/images/logo-sm.png"
import logoLightPng from "../../assets/images/logo.png"
import logoDark from "../../assets/images/logo.png"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"
import NotificationDropdown from 'components/CommonForBoth/TopbarDropdown/NotificationDropdown'

const Header = props => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const navigate = useNavigate();

  // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const userRoutes = [
    { path: "/dashboard", name: "Dashboard" },
    { path: "/total-unbilled-report", name: "Total Unbilled Report" },
    { path: "/bills-pending-approval", name: "Bills Pending Approval" },
    { path: "/delivered-lrs-unbilled-report", name: "Delivered LRs Unbilled Report" },
    { path: "/pod-uploaded-but-bill-pending", name: "POD Uploaded But Bill Pending" },
    { path: "/pod-upload-but-mr-pending", name: "POD Uploaded But MR Pending" },
    { path: "/billing-time-analysis", name: "Billing Time Analysis" },
    { path: "/billing", name: "Billing Dashboard" },
    { path: "/credit-control", name: "Customer Outstanding Dashboard" },
    { path: "/branch-details", name: "Branch Details" },
    { path: "/customer-bills", name: "Customer Bills" },
    { path: "/booking", name: "Booking Dashboard" },
    { path: "/booking/lrs-pending-dispatch", name: "LRs Pending Dispatch" },
    { path: "/booking/door-challan", name: "Door Challan" },
    { path: "/booking/lr-with-freight", name: "LR with Freight" },
    { path: "/inward", name: "Inward Dashboard" },
    { path: "/inward/lhs-pending-vehicle-arrival", name: "LHS Pending Vehicle Arrival" },
    { path: "/inward/lhs-pending-vehicle-unloading", name: "LHS Pending Vehicle Unloading" },
    { path: "/inward/incoming-consignment-report", name: "Incoming Consignment Report" },
    { path: "/pod", name: "POD Dashboard" },
    { path: "/pod/Lr-pending-for-pod-billing", name: "LR Pending for POD Billing" },
    { path: "/pod/Lr-pending-for-pod", name: "LR Pending for POD" },
    { path: "/pod/lr-pending-for-pod-ftl", name: "LR Pending for POD FTL" },
    { path: "/pod/lr-pending-for-pod-ftl-marketing", name: "LR Pending for POD FTL Marketing" },
    { path: "/pod/lr-pending-for-sundry", name: "LR Pending for Sundry" },
    { path: "/delivery", name: "Delivery Dashboard" },
    { path: "/delivery/pre-door-delivery-sheet", name: "Pre Door Delivery Sheet" },
    { path: "/delivery/pdds-pending-for-door-delivery", name: "PDDS Pending for Door Delivery" },
    { path: "/delivery/lr-pending-for-gate-pass", name: "LR Pending for Gate Pass" },
    { path: "/delivery/lr-pending-for-bill-gate-pass", name: "LR Pending for Bill Gate Pass" },
    { path: "/delivery/lr-pending-for-bank-memo-gate-pass", name: "LR Pending for Bank Memo Gate Pass" },
    { path: "/delivery/lr-pending-for-direct-door-delivery-from-branch", name: "LR Pending for Direct Door Delivery From Branch" },
    { path: "/delivery/lr-pending-for-direct-door-delivery-to-branch", name: "LR Pending for Direct Door Delivery To Branch" }
  ];

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query.length > 0) {
      const filtered = userRoutes.filter(route =>
        route.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredRoutes(filtered);
    } else {
      setFilteredRoutes([]);
    }
  };

  const handleRouteSelect = (path) => {
    setSearchQuery("");
    setFilteredRoutes([]);
    navigate(path);
  };

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="45" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="50" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logo} alt="" height="45" />
                </span>
                <span className="logo-lg">
                  <img src={logoLightPng} alt="" height="50" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-24 header-item waves-effect vertical-menu-btn"
              id="vertical-menu-btn"
            >
              <i className="mdi mdi-menu"></i>
            </button>
          </div>
          <div className="d-flex">
            <div className="position-relative app-search d-none d-lg-block">
              <div className="input-group" style={{ position: 'relative' }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder={props.t("Search") + "..."}
                  value={searchQuery}
                  onChange={handleSearchChange}
                  style={{
                    paddingRight: '40px',
                  }}
                />
                <span className="fa fa-search" style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: '#999',
                  pointerEvents: 'none',
                }}></span>
              </div>

              {filteredRoutes.length > 0 && (
                <div className="dropdown-menu show search-dropdown" style={{
                  position: 'absolute',
                  top: '100%',
                  left: '0',
                  right: '0',
                  backgroundColor: '#ffffff',
                  borderRadius: '6px',
                  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                  marginTop: '4px',
                  zIndex: '1000',
                  padding: '4px 0',
                  maxHeight: '240px',
                  overflowY: 'auto',
                  border: '1px solid #ddd',
                  scrollbarWidth: 'thin',
                  scrollbarColor: '#ccc #f0f0f0',
                }}>
                  {filteredRoutes.map(route => (
                    <button
                      key={route.path}
                      onClick={() => handleRouteSelect(route.path)}
                      className="dropdown-item"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        padding: '8px 12px',
                        textAlign: 'left',
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: '#333',
                        fontSize: '14px',
                        cursor: 'pointer',
                        transition: 'background-color 0.2s ease, color 0.2s ease',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                      onMouseOver={(e) => {
                        e.currentTarget.style.backgroundColor = '#f0f0f0';
                        e.currentTarget.style.color = '#007bff';
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.backgroundColor = 'transparent';
                        e.currentTarget.style.color = '#333';
                      }}
                    >
                      {route.name}
                    </button>
                  ))}
                </div>
              )}
            </div>


            {/* <div className="dropdown d-none d-lg-inline-block">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="mdi mdi-fullscreen font-size-24"></i>
              </button>
            </div> */}
            <NotificationDropdown />
            <ProfileMenu />
            {/* <div
              onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar)
              }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle waves-effect"
              >
                <i className="mdi mdi-spin mdi-cog"></i>
              </button>
            </div> */}
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
