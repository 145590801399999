import axios from "axios";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const API_URL = {
    //Authentication Apis
    login: "/iam/login/",
    logout: "/iam/logout/",

    //Billing 
    freightBillsHierarchical: "/billing/freight-bills-aggregate/",
    totalUnbilledHierarchical: "/billing/total-unbilled-hierarchical/",
    PoDUploadedButMRPending: "/billing/pod-uploaded-mr-pending-hierarchical/",
    PoDUploadedButBillPending: "/billing/pod-uploaded-received-bill-pending-hierarchical/",
    BillsPendingApproval: "/billing/bills-pending-approval-hierarchical/",
    DeliveredLrsUnbilledReport: "/billing/delivered-lrs-unbilled-hierarchical/",
    podUploadedMrPending: "/billing/pod-uploaded-mr-pending/",
    podUploadedReceivedBillPending: "/billing/pod-uploaded-received-bill-pending/",
    freightBills: "/billing/freight-bills/",
    totalUnbilledreport: "/billing/total-unbilled-report/",
    deliveredLrsUnbilledReport: "/billing/delivered-lrs-unbilled-report/",
    billsPendingApproval: "/billing/bills-pending-approval/",

    //Customer Outstanding
    customerExposureAnalysis: "/credit/customer-exposure-analysis/",
    customerAgingBar: "/credit/top-20-parties/",
    customerAgingZone: "/credit/customer-aging-zone-wise/",
    agingPartyWise: "/credit/customer-aging-party-wise/",
    paymentTimeAnalysis: "/credit/payment-time-analysis/",
    creditTimeAnalysis: "/credit/credit-time-analysis/",
    customerAging: "/credit/all-parties/",
    customerAgingAmountDifference: "/credit/customer-aging-amount-difference/",

    // Booking Dashboard
    lrPendingForDispatchHierarchical: "/billing/lr-pending-for-dispatch-hierarchical/",
    lrPendingDispatch: "/billing/lr-pending-dispatch/",
    lrPendingForDoorChallenHierarchical: "/billing/lr-pending-for-door-challen-hierarchical/",
    lrPending: "/billing/lr-pending/",
    lrFreightBookingwiseHierarchical: "/billing/lr_re1_freight_bookingwise-hierarchical/",
    lrFreightBookingwise: "/billing/lr_re1_freight_bookingwise/",


    // Inward Dashboard 
    LHSPendingForVehichleArrivalHierarchical: "/inward/lhs-pending-vehicle-arrivals-aggregate/",
    LHSPendingForVehichleArrival: "/inward/lhs-pending-vehicle-arrivals/",
    LHSPendingForVehichleUnloadingHierarchical: "/inward/lhs-pending-vehicle-unloading-aggregate/",
    LHSPendingForVehichleUnloading: "/inward/lhs-pending-vehicle-unloading/",
    incomingConsignmentHierarchical: "/inward/incoming-consignment-hierarchical/",
    incomingConsignment: "/inward/incoming-consignment/",

    //Pod Dashboard
    ftlBillingWiseHierarchical: "/pod/ftl-billing-wise-hierarchical/",
    ftlBillingWise: "/pod/ftl-billing-wise/",
    ftlMarketingExecutiveHierarchical: "/pod/ftl-marketing-executive-hierarchical/",
    ftlMarketingExecutive: "/pod/ftl-marketing-executive/",
    ftlTrafficInchargeHierarchical: "/pod/ftl-traffic-incharge-hierarchical/",
    ftlTrafficIncharge: "/pod/ftl-traffic-incharge/",
    sundryMarketingExecutiveHierarchical: "/pod/sundry-marketing-executive-hierarchical/",
    sundryMarketingExecutive: "/pod/sundry-marketing-executive/",
    sundryWiseHierarchical: "/pod/sundry-wise-hierarchical/",
    sundryWise: "/pod/sundry-wise/",
    
    //Delivery Dashboard
    lhsPendingDDDFromLocation: "/delivery/lhs-pending-ddd-from-location/",
    lhsPendingDDDFromLocationHierarchical: "/delivery/lhs-pending-ddd-from-location-hierarchical/",
    lhsPendingDDDToLocation: "/delivery/lhs-pending-ddd-to-location/",
    lhsPendingDDDToLocationHierarchical: "/delivery/lhs-pending-ddd-to-location-hierarchical/",
    lhsPendingPreDD: "/delivery/lhs-pending-pre-dd/",
    lhsPendingPreDDHierarchical: "/delivery/lhs-pending-pre-dd-hierarchical/",
    lhsPendingDDSettlement: "/delivery/lhs-pending-dd-settlement/",
    lhsPendingDDSettlementHierarchical: "/delivery/lhs-pending-dd-settlement-hierarchical/",
    lrPendingForBillCumGatePass: "/delivery/lr-pending-for-bill-cum-gate-pass/",
    lrPendingForBillCumGatePassHierarchical: "/delivery/lr-pending-for-bill-cum-gate-pass-hierarchical/",
    lrPendingForCashMemoGatePass: "/delivery/lr-pending-for-cash-memo-gate-pass/",
    lrPendingForCashMemoGatePassHierarchical: "/delivery/lr-pending-for-cash-memo-gate-pass-hierarchical/",
    lrPendingForGatePass: "/delivery/lr-pending-for-gate-pass/",
    lrPendingForGatePassHierarchical: "/delivery/lr-pending-for-gate-pass-hierarchical/",
    
    
    //Summary 
    customerOutstandingSummary: "/credit/customer-aging-stats/",
    billingSummary: "/billing/billing-summary/",
    bookingSummary: "/billing/booking-lr-count/",
    inwardSummary: "/inward/inward-summary/",
    deliverySummary: "/delivery/delivery-summary/",
    podSummary: "/pod/pod-summary/",

    // Filters
    customerOutstandingFilter: "/credit/customer-aging-filters/",
    billingCommonFilter: "/billing/billing-common-filter/",
    bookingCommonFilter: "/billing/booking-common-filter/",
    inwardCommonFilter: "/inward/inward-common-filter/",
    podCommonFilter: "/pod/pod-common-filter/",
    deliveryCommonFilter: "/delivery/delivery-common-filter/",

};

export async function login(body) {
    return new Promise((resolve) => {
        axios
            .post(`${baseUrl}${API_URL.login}`, body)
            .then((res) => {
                resolve(res.data);
                return res.data;
            })
            .catch((error) => {
                console.log(error);
                resolve(error.response.data);
            });
    });
}

export async function logout(body) {
    return new Promise((resolve) => {
        axios
            .post(`${baseUrl}${API_URL.logout}`, body, {
                withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
                return res.data;
            })
            .catch((error) => {
                console.log(error);
                resolve(error.response.data);
            });
    });
}

export async function getfreightBillsHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.freightBillsHierarchical}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function gettotalUnbilledHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.totalUnbilledHierarchical}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getPoDUploadedButMRPending(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.PoDUploadedButMRPending}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getPoDUploadedButBillPending(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.PoDUploadedButBillPending}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getBillsPendingApproval(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.BillsPendingApproval}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getDeliveredLrsUnbilledReport(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.DeliveredLrsUnbilledReport}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getpodUploadedMrPending(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.podUploadedMrPending}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getpodUploadedReceivedBillPending(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.podUploadedReceivedBillPending}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getfreightBills(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.freightBills}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function gettotalUnbilledreport(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.totalUnbilledreport}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getdeliveredLrsUnbilledReport(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.deliveredLrsUnbilledReport}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getbillsPendingApproval(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.billsPendingApproval}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getcustomerExposureAnalysis(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.customerExposureAnalysis}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getcustomerAgingBar(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.customerAgingBar}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getcustomerAgingZone(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.customerAgingZone}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getagingPartyWise(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.agingPartyWise}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getPaymentTimeAanalysis(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.paymentTimeAnalysis}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getCreditTimeAanalysis(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.creditTimeAnalysis}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getcustomerAging(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.customerAging}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getcustomerAgingAmountDifference(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.customerAgingAmountDifference}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getcustomerOutstandingSummary(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.customerOutstandingSummary}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getcustomerOutstandingFilter(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.customerOutstandingFilter}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getbillingCommonFilter(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.billingCommonFilter}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getbillingSummary(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.billingSummary}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getlrPendingDispatch(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.lrPendingDispatch}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getlrPendingForDispatchHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.lrPendingForDispatchHierarchical}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getlrPendingForDoorChallenHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.lrPendingForDoorChallenHierarchical}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getlrPending(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.lrPending}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getbookingSummary(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.bookingSummary}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getbookingCommonFilter(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.bookingCommonFilter}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}
export async function getInwardCommonFilter(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.inwardCommonFilter}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}
export async function getPodCommonFilter(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.podCommonFilter}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}
export async function getdeliveryCommonFilter(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.deliveryCommonFilter}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getLHSPendingForVehichleArrivalHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.LHSPendingForVehichleArrivalHierarchical}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getLHSPendingForVehichleArrival(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.LHSPendingForVehichleArrival}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getLHSPendingForVehichleUnloadingHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.LHSPendingForVehichleUnloadingHierarchical}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getLHSPendingForVehichleUnloading(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.LHSPendingForVehichleUnloading}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}
export async function getincomingConsignmentHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.incomingConsignmentHierarchical}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}
export async function getincomingConsignment(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.incomingConsignment}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}
export async function getlrFreightBookingwise(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.lrFreightBookingwise}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}
export async function getlrFreightBookingwiseHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.lrFreightBookingwiseHierarchical}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}
export async function getinwardSummary(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.inwardSummary}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}
export async function getPodSummary(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.podSummary}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getFtlBillingWiseHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.ftlBillingWiseHierarchical}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getFtlBillingWise(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.ftlBillingWise}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getFtlMarketingExecutiveHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.ftlMarketingExecutiveHierarchical}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getFtlMarketingExecutive(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.ftlMarketingExecutive}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getFtlTrafficInchargeHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.ftlTrafficInchargeHierarchical}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getFtlTrafficIncharge(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.ftlTrafficIncharge}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getSundryMarketingExecutiveHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.sundryMarketingExecutiveHierarchical}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getSundryMarketingExecutive(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.sundryMarketingExecutive}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getSundryWiseHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.sundryWiseHierarchical}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getSundryWise(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.sundryWise}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}
export async function getLhsPendingDDDFromLocation(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.lhsPendingDDDFromLocation}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getLhsPendingDDDFromLocationHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.lhsPendingDDDFromLocationHierarchical}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getLhsPendingDDDToLocation(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.lhsPendingDDDToLocation}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getLhsPendingDDDToLocationHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.lhsPendingDDDToLocationHierarchical}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getLhsPendingPreDD(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.lhsPendingPreDD}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getLhsPendingPreDDHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.lhsPendingPreDDHierarchical}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getLhsPendingDDSettlement(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.lhsPendingDDSettlement}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getLhsPendingDDSettlementHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.lhsPendingDDSettlementHierarchical}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getLrPendingForBillCumGatePass(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.lrPendingForBillCumGatePass}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getLrPendingForBillCumGatePassHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.lrPendingForBillCumGatePassHierarchical}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getLrPendingForCashMemoGatePass(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.lrPendingForCashMemoGatePass}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getLrPendingForCashMemoGatePassHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.lrPendingForCashMemoGatePassHierarchical}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getLrPendingForGatePass(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.lrPendingForGatePass}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}

export async function getLrPendingForGatePassHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.lrPendingForGatePassHierarchical}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}
export async function getDeliverySummary(queryParams) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrl}${API_URL.deliverySummary}`, {
                params: queryParams, withCredentials: true
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error.response.status);
            });
    });
}
