import React , {useEffect,useRef} from "react"
import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { Card,CardTitle } from "reactstrap";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

import SummaryCards from "components/CustomComponents/SummaryCards";
import { getincomingConsignmentHierarchicalReq, getincomingConsignmentReq, getinwardSummaryReq, getLHSPendingForVehichleArrivalHierarchicalReq, getLHSPendingForVehichleArrivalReq,
    getLHSPendingForVehichleUnloadingHierarchicalReq, getLHSPendingForVehichleUnloadingReq } from "./inwardService";
import DrillDownTable from "components/CustomComponents/DrillDownTable";
import InwardFilterBar from "./InwardFilterBar";

const Booking = (props) => {

  document.title = "Aurum | Inward";
  const navigate = useNavigate();
  const tableRefs = useRef([]);
  const [filters, setFilters] = useState({});

  const breadcrumbItems = [
    { title: "Home", link: "/dashboard" },
    { title: "Inward", link: "/inward" },
  ]


  const handleCardTitleClick = (destinationPage) => (event) => {
    event.stopPropagation();
    navigate(destinationPage);
  };

  useEffect(() => {
    props.setBreadcrumbItems('Inward' , breadcrumbItems);
  },)

  const applyFilters = (filters) => {
    // console.log("Applied Filters:", filters);
    setFilters(filters || {});
  };
  
  return (
    <React.Fragment >
      <SummaryCards fetchDataFn={getinwardSummaryReq}/>
        <div style={{ 
          display: 'flex', 
          flexWrap: 'wrap', 
          gap: '12px' 
        }}>
          <InwardFilterBar onApplyFilters={applyFilters} filters={filters}/>
          <Card body style={{ flex:' 1 1 calc(50% - 12px)', boxSizing: 'border-box', minHeight: '300px'}} className="overflow-hidden">
            <CardTitle onClick={handleCardTitleClick("lhs-pending-vehicle-arrival")} style={{ cursor:"pointer"}} className="h4">LHS Pending for Vehicle Arrival</CardTitle>
            <DrillDownTable
              ref={el => tableRefs.current[1] = el}
              fetchDataFn={getLHSPendingForVehichleArrivalHierarchicalReq} 
              fetchDataAtBreadcrumbLimit={getLHSPendingForVehichleArrivalReq}  
              destinationPage={"lhs-pending-vehicle-arrival"}
              initialQueryParams={filters}
              color='#FFE3E1'
            />
          </Card>
          <Card body style={{ flex:' 1 1 calc(50% - 12px)', boxSizing: 'border-box', minHeight: '300px' }} className="overflow-hidden">
          <CardTitle onClick={handleCardTitleClick("lhs-pending-vehicle-unloading")} style={{ cursor:"pointer"}} className="h4">LHS Pending for Vehicle Unloading</CardTitle>
          <DrillDownTable 
              ref={el => tableRefs.current[0] = el}
              fetchDataFn={getLHSPendingForVehichleUnloadingHierarchicalReq} 
              fetchDataAtBreadcrumbLimit={getLHSPendingForVehichleUnloadingReq}  
              destinationPage={"lhs-pending-vehicle-unloading"}
              initialQueryParams={filters}
              color='#FFE5D4'
            />
          </Card>
          <Card body style={{ flex:' 1 1 calc(50% - 12px)', boxSizing: 'border-box', minHeight: '300px' }} className="overflow-hidden">
          <CardTitle onClick={handleCardTitleClick("incoming-consignment-report")} style={{ cursor:"pointer"}} className="h4">Incoming Consignment Report (To Branch Wise)</CardTitle>
          <DrillDownTable 
              ref={el => tableRefs.current[0] = el}
              fetchDataFn={getincomingConsignmentHierarchicalReq} 
              fetchDataAtBreadcrumbLimit={getincomingConsignmentReq}  
              destinationPage={"lhs-pending-vehicle-unloading"}
              initialQueryParams={filters}
              color='#F1D9F7'
            />
          </Card>
      </div>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Booking);