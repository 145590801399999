import React, { useRef, useEffect, useCallback, useState, memo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { capitalizeWords } from 'utils';
import { columnLabelDict } from 'pages/Billing/Constants/contants';

const EnhancedTable = memo(({ data, columns }) => {
  const gridRef = useRef(null); // Reference for the grid
  const paginationPageSizeSelector = [10, 25, 50];
  const [paginationPageSize, setPaginationPageSize] = useState(10);
  const [isGridInitialized, setIsGridInitialized] = useState(false);

  // Define column definitions for ag-grid with initial sizes
  const columnDefs = columns.map(col => ({
    headerName: columnLabelDict[col] || capitalizeWords(col.replace(/_/g, ' ')),
    field: col,
    sortable: true,
    filter: true,
    resizable: true,
    cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' } // Prevent wrapping
  }));

  // Define a function to determine row style based on conditions
  const getRowStyle = params => {
    if (params.data.days_0_30 > 0) {
      return {
        backgroundColor: '#C8E6C9' // Muted light green background
      };
    }
    if (params.data.days_31_60 > 0) {
      return {
        backgroundColor: '#EFEBE9' // Muted light brown background
      };
    }
    if (params.data.days_61_90 > 0) {
      return {
        backgroundColor: '#FFE0B2' // Muted light orange background
      };
    }
    if (params.data.days_91_180 > 0) {
      return {
        backgroundColor: '#F8BBD0' // Muted pink background
      };
    }
    if (params.data.days_over_181 > 0) {
      return {
        backgroundColor: '#FFCDD2' // Dark red background
      };
    }if(params.data.delay_days_n_hours){
      const delayDays = parseInt(params.data.delay_days_n_hours.split(' ')[0], 10);
      if (delayDays > 31) {
        return { backgroundColor: '#FF6F61' };
      } else if (delayDays >= 16 && delayDays <= 30) {
        return { backgroundColor: '#FFA500' };
      }
    }
    return null; // Default style if condition is not met
  };

  const onPaginationChanged = useCallback((event) => {
    let pageSize = gridRef.current.api.paginationGetPageSize();
    setPaginationPageSize(pageSize);
  }, []);

  const autoSizeStrategy = {
    type: 'fitCellContents'
  };

  // Grid ready callback
  const onGridReady = (params) => {
    gridRef.current = params; // Store grid API reference
  };

  // Ensure the table is rendered only once
  useEffect(() => {
    if (!isGridInitialized) {
      setIsGridInitialized(true);
    }
  }, [isGridInitialized]);

  return (
    <div style={{ padding: '8px' }}>
      <div
        className="ag-theme-quartz"
        style={{ width: '100%' }}
      >
        {isGridInitialized && (
          <AgGridReact
            ref={gridRef}
            rowData={data}
            columnDefs={columnDefs}
            pagination={true}
            onPaginationChanged={onPaginationChanged}
            paginationPageSize={paginationPageSize}
            paginationPageSizeSelector={paginationPageSizeSelector}
            defaultColDef={{
              filter: true,
              sortable: true,
              resizable: true,
              floatingFilter: false,
            }}
            onGridReady={onGridReady}
            domLayout='autoHeight'
            autoSizeStrategy={autoSizeStrategy}
            getRowStyle={getRowStyle}
          />
        )}
      </div>
    </div>
  );
});

export default EnhancedTable;
