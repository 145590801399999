import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { withTranslation } from "react-i18next";

const NotificationDropdown = props => {
  const [menu, setMenu] = useState(false);

  const notifications = [
    {
      icon: "mdi mdi-office-building",
      color: "text-primary",
      title: "Regional Office Update",
      description: "New performance report available for Regional Office 1."
    },
    {
      icon: "mdi mdi-city",
      color: "text-success",
      title: "Area Office Alert",
      description: "Important compliance alert for Area Office 3."
    },
    {
      icon: "mdi mdi-bank",
      color: "text-warning",
      title: "Collection Branch Update",
      description: "New payment collection report generated for Collection Branch A."
    },
    {
      icon: "mdi mdi-account-group",
      color: "text-info",
      title: "Party Name Notification",
      description: "Payment received from Party XYZ."
    },
    {
      icon: "mdi mdi-account-tie",
      color: "text-secondary",
      title: "Marketing Executive Reminder",
      description: "Follow-up required with 5 clients by Marketing Executive John Doe."
    },
    {
      icon: "mdi mdi-account-cash",
      color: "text-danger",
      title: "Collection Executive Task",
      description: "Pending collections need attention from Collection Executive Jane Smith."
    }
  ];

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block ms-1"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="ti-bell"></i>
          <span className="badge text-bg-danger rounded-pill">6</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 shadow-lg border-0" style={{ borderRadius: '10px' }}>
          <div className="p-3 bg-primary text-white rounded-top">
            <Row className="align-items-center">
              <Col>
                <h5 className="m-0">{props.t("Notifications")} (6)</h5>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "330px" }}>
            {notifications.map((notification, index) => (
              <Link to="#" className="text-reset notification-item d-flex align-items-center p-3" key={index} style={{ transition: 'background 0.3s', borderBottom: '1px solid #e9ecef' }}>
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-xs">
                    <span className={`avatar-title rounded-circle ${notification.color} bg-light`} style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                      <i className={notification.icon}></i>
                    </span>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1 font-weight-bold" style={{ fontSize: '14px' }}>{notification.title}</h6>
                  <p className="text-muted mb-0" style={{ fontSize: '12px' }}>{notification.description}</p>
                </div>
              </Link>
            ))}
          </SimpleBar>

          {/* <div className="p-2 border-top d-grid bg-light rounded-bottom">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="#"
              style={{ fontWeight: 'bold' }}
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>
              {props.t("View all")}
            </Link>
          </div> */}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

NotificationDropdown.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(NotificationDropdown);
