import React, { useState, useEffect, forwardRef } from 'react';
import Spinners from 'components/Common/Spinner';
import EnhancedTable from './EnhancedTable';
import {
    formatNumber,
    capitalizeWords,
    calculateTotalRow,
    createQueryParamsFromBreadcrumbs,
    darkenColor
} from 'utils';
import { useNavigate } from 'react-router-dom';

const BillingDrill = forwardRef(({ uniqueKey, fetchDataFn, fetchDataAtBreadcrumbLimit, destinationPage, color }, ref) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [apiLoading, setApiLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [columnHeaders, setColumnHeaders] = useState([]);
    const [queryParams, setQueryParams] = useState({});
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [useEnhancedTable, setUseEnhancedTable] = useState(false);
    const navigate = useNavigate();

    // On Mount: Restore state from localStorage
    useEffect(() => {
        const savedQueryParams = localStorage.getItem(`${uniqueKey}_queryParams`);
        const savedBreadcrumbs = localStorage.getItem(`${uniqueKey}_breadcrumbs`);

        const initialBreadcrumbs = savedBreadcrumbs ? JSON.parse(savedBreadcrumbs) : [{ label: 'Home', field: '' }];
        if (destinationPage && initialBreadcrumbs.length > 4) {
            setBreadcrumbs([{ label: 'Home', field: '' }]);
            setQueryParams({});
        } else {
            setBreadcrumbs(initialBreadcrumbs);
            setQueryParams(savedQueryParams ? JSON.parse(savedQueryParams) : {});
        }
    }, [uniqueKey]);

    // Store queryParams and breadcrumbs in localStorage when they change
    useEffect(() => {
        if (queryParams) {
            localStorage.setItem(`${uniqueKey}_queryParams`, JSON.stringify(queryParams));
        }
    }, [uniqueKey, queryParams]);

    useEffect(() => {
        if (breadcrumbs) {
            localStorage.setItem(`${uniqueKey}_breadcrumbs`, JSON.stringify(breadcrumbs));
        }
    }, [uniqueKey, breadcrumbs]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true); // Start loading before making the API call
                setApiLoading(true); // Start loading before making the API call
                
                // Simulate a delay using setTimeout
                // await new Promise((resolve) => setTimeout(resolve, 10000)); // 10-second delay
                
                const combinedParams = { ...queryParams };
                
                let response;
                if (breadcrumbs.length === 5) {
                    setUseEnhancedTable(true);
                    response = await fetchDataAtBreadcrumbLimit(combinedParams);
                } else {
                    setUseEnhancedTable(false);
                    response = await fetchDataFn(combinedParams);
                }
                
                const responseData = response?.results;
                setData(responseData);
                
                if (responseData) {
                    setApiLoading(false);
                    const firstRow = responseData[0];
                    setColumns(Object.keys(firstRow));
                    setColumnHeaders(response?.column_headers);
                } else {
                    setColumns([]);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setData([]);
                setColumns([]);
                setApiLoading(false);
            } finally {
                setLoading(false); // Stop loading after the API call completes
            }
        };

        fetchData();
    }, [fetchDataFn, fetchDataAtBreadcrumbLimit, breadcrumbs, queryParams]);


    // Handle Row Click and Update Breadcrumbs
    const handleRowClick = (field, value) => {
        const newParams = {
            ...queryParams,
            ...(field && { [field]: value }),
        };
        const newBreadcrumbs = [...breadcrumbs, { label: value, field }];
        setQueryParams(newParams);
        setBreadcrumbs(newBreadcrumbs);

        // Update local storage immediately after setting new state
        localStorage.setItem(`${uniqueKey}_queryParams`, JSON.stringify(newParams));
        localStorage.setItem(`${uniqueKey}_breadcrumbs`, JSON.stringify(newBreadcrumbs));

        if (breadcrumbs.length >= 4) {
            if (destinationPage) {
                navigate(destinationPage);
            }
        }
    };

    // Handle Breadcrumb Click and Update State
    const handleBreadcrumbClick = (index) => {
        const newBreadcrumbs = breadcrumbs.slice(0, index + 1);
        const newParams = createQueryParamsFromBreadcrumbs(newBreadcrumbs);

        setQueryParams(newParams);
        setBreadcrumbs(newBreadcrumbs);
    };

    const totalRow = calculateTotalRow(data, columns);

    const renderContent = () => {
        if (apiLoading) {
            return (
                <div style={{ position: 'relative' }}>
                    <Spinners setLoading={setLoading} />
                </div>
            );
        }

        if (!apiLoading && useEnhancedTable) {
            return <EnhancedTable data={data} columns={columns} />;
        }

        if (!apiLoading && data?.length === 0) {
            return <p style={{ textAlign: 'center', color: 'green' }}>Good Work, All Tasks Are Completed.</p>;
        }

        return (
            !apiLoading && <div style={{ overflowX: 'auto' }}>
                <table ref={ref} style={{ minWidth: '100%', backgroundColor: 'white', borderCollapse: 'collapse', border: `1px solid ${darkenColor(color, 0.2)}`, fontSize: '12px' }}>
                    <thead style={{ backgroundColor: color }}>
                        <tr>
                            {columns?.map((col, index) => (
                                <th key={index} style={{ borderBottom: `2px solid ${darkenColor(color, 0.2)}`, padding: '12px', textAlign: 'left', backgroundColor: color }}>
                                    {columnHeaders[col] || capitalizeWords(col.replace(/_/g, ' '))}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((row, rowIndex) => (
                            <tr
                                key={rowIndex}
                                onClick={() => {
                                    if (breadcrumbs.length <= 4) {
                                        handleRowClick(Object.keys(row)[0].toLowerCase().replace(' ', '_'), row[Object.keys(row)[0]]);
                                    }
                                }}
                                style={{
                                    cursor: breadcrumbs.length >= 5 ? 'default' : 'pointer',
                                    backgroundColor: '#f9f9f9',
                                }}
                            >
                                {columns?.map((col, colIndex) => (
                                    <td key={colIndex} style={{ borderBottom: `1px solid ${darkenColor(color, 0.2)}`, padding: '12px' }}>
                                        {col === columns[0]
                                            ? row[col]
                                            : typeof row[col] === 'number'
                                                ? formatNumber(row[col])
                                                : row[col]
                                        }
                                    </td>
                                ))}
                            </tr>
                        ))}
                        <tr style={{ backgroundColor: color }}>
                            <td style={{ borderTop: `2px solid ${darkenColor(color, 0.2)}`, padding: '12px', fontWeight: 'bold' }}>Total</td>
                            {columns?.slice(1).map((col, index) => (
                                <td key={index} style={{ borderTop: `2px solid ${darkenColor(color, 0.2)}`, padding: '12px', fontWeight: 'bold' }}>
                                    {typeof totalRow[col] === 'number'
                                        ? formatNumber(totalRow[col])
                                        : totalRow[col]
                                    }
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div>
            {/* Breadcrumbs */}
            {data?.length === 0 ? null : (
                <nav style={{ marginBottom: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', color: '#4a4a4a', fontSize: '10px' }}>
                        {breadcrumbs.map((crumb, index) => (
                            <React.Fragment key={index}>
                                {index > 0 && <span style={{ color: '#9e9e9e', margin: '0 8px' }}>/</span>}
                                {index === breadcrumbs.length - 1 ? (
                                    <span style={{ fontWeight: 'bold' }}>{crumb.label}</span>
                                ) : (
                                    <span
                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                        onClick={() => handleBreadcrumbClick(index)}
                                    >
                                        {crumb.label}
                                    </span>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </nav>
            )}

            {/* Table Rendering */}
            {/* {loading ? (
                <div style={{ position: 'relative' }}>
                    <Spinners setLoading={setLoading} />
                </div>
            ) : useEnhancedTable ? (
                <EnhancedTable data={data} columns={columns} />
            ) : (
                data?.length === 0 ? (
                    <p style={{ textAlign: 'center', color: 'green' }}>Good Work, All Tasks Are Completed.</p>
                ) : (
                    <div style={{ overflowX: 'auto' }}>
                        <table ref={ref} style={{ minWidth: '100%', backgroundColor: 'white', borderCollapse: 'collapse', border: `1px solid ${darkenColor(color, 0.2)}`, fontSize: '12px' }}>
                            <thead style={{ backgroundColor: color }}>
                                <tr>
                                    {columns?.map((col, index) => (
                                        <th key={index} style={{ borderBottom: `2px solid ${darkenColor(color, 0.2)}`, padding: '12px', textAlign: 'left', backgroundColor: color }}>
                                            {columnHeaders[col] || capitalizeWords(col.replace(/_/g, ' '))}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((row, rowIndex) => (
                                    <tr
                                        key={rowIndex}
                                        onClick={() => {
                                            if (breadcrumbs.length <= 4) {
                                                handleRowClick(Object.keys(row)[0].toLowerCase().replace(' ', '_'), row[Object.keys(row)[0]]);
                                            }
                                        }}
                                        style={{
                                            cursor: breadcrumbs.length >= 5 ? 'default' : 'pointer',
                                            backgroundColor: '#f9f9f9',
                                        }}
                                    >
                                        {columns?.map((col, colIndex) => (
                                            <td key={colIndex} style={{ borderBottom: `1px solid ${darkenColor(color, 0.2)}`, padding: '12px' }}>
                                                {col === columns[0]
                                                    ? row[col]
                                                    : typeof row[col] === 'number'
                                                        ? formatNumber(row[col])
                                                        : row[col]
                                                }
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                <tr style={{ backgroundColor: color }}>
                                    <td style={{ borderTop: `2px solid ${darkenColor(color, 0.2)}`, padding: '12px', fontWeight: 'bold' }}>Total</td>
                                    {columns?.slice(1).map((col, index) => (
                                        <td key={index} style={{ borderTop: `2px solid ${darkenColor(color, 0.2)}`, padding: '12px', fontWeight: 'bold' }}>
                                            {typeof totalRow[col] === 'number'
                                                ? formatNumber(totalRow[col])
                                                : totalRow[col]
                                            }
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            )} */}
            {renderContent()}
        </div>
    );
});

export default BillingDrill;
