import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "pages/Authentication/Logout"
// Inner Authentication
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Billing from "pages/Billing/Billing"
import CreditControl from "pages/Credit-Control/CreditControl"
import Booking from "pages/Booking/Booking"
import Inward from "pages/inward/Inward"
import Pod from "pages/Pod/Pod"
//Extra Pages
import Pages404 from "../pages/Extra Pages/pages-404";
import Pages500 from "../pages/Extra Pages/pages-500";


//totalUnbilledReport
import TotalUnbilledReport from "pages/Billing/TotalUnbilledReport"
import BillsPendingApproval from "pages/Billing/BillsPendingApproval"
import DeliveredLrsUnbilledReport from "pages/Billing/DeliveredLrsUnbilledReport"
import PoDUploadedButBillPending from "pages/Billing/PoDUploadedButBillPending"
import PoDUploadedButMRPending from "pages/Billing/PoDUploadedButMRPending"
import BillingTimeAnalysis from "pages/Billing/BillingTimeAnalysis"
import CustomerReport from "pages/Credit-Control/BranchDetails"
import CustomerBillsPending from "pages/Credit-Control/CustomerBillsPending"
import LrPendingforDispatch from "pages/Booking/LrPendingforDispatch"
import DoorChallan from "pages/Booking/doorChallan"
import VehicleArrival from "pages/inward/vehicleArrival"
import VehicleUnloading from "pages/inward/vehicleUnloading"
import IncomingConsignmentReport from "pages/inward/IncomingConsignmentReport"
import LrWithFreight from "pages/Booking/LrWithFreight"
import Delivery from "pages/Delivery/Delivery"
import LRPendingForPODBilling from "pages/Pod/LRPendingForPODBilling"
import LRPendingForPOD from "pages/Pod/LRPendingForPOD"
import LRPendingForPODFTL from "pages/Pod/LRPendingForPODFTL"
import LRPendingForPODFTLMarketing from "pages/Pod/LRPendingForPODFTLMarketing"
import LRPendingForPODSundry from "pages/Pod/LRPendingForPODSundry"
import PreDoorDeliverySheet from "pages/Delivery/PreDoorDeliverySheet"
import PDDSPendingForDoorDeliverySettlement from "pages/Delivery/PDDSPendingForDoorDeliverySettlement"
import LRsPendingForGatePassCash from "pages/Delivery/LRsPendingForGatePassCash"
import LRsPendingForBillGatePass from "pages/Delivery/LRsPendingForBillGatePass"
import LRsPendingForBankMemocumGatePass from "pages/Delivery/LRsPendingForBankMemocumGatePass"
import LHSpendingforDirectDoorDeliveryFromBranch from "pages/Delivery/LHSpendingforDirectDoorDeliveryFromBranch"
import LHSpendingforDirectDoorDeliveryToBranchCopy from "pages/Delivery/LHSpendingforDirectDoorDeliveryToBranch"

const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

   // // //profile
  { path: "/profile", component: <UserProfile /> },
  

  // //totalUnbilledReport
  { path: "/billing/total-unbilled-report", component: <TotalUnbilledReport /> },
  { path: "/billing/bills-pending-approval", component: <BillsPendingApproval/> },
  { path: "/billing/delivered-lrs-unbilled-report", component: <DeliveredLrsUnbilledReport /> },
  { path: "/billing/pod-uploaded-but-bill-pending", component: <PoDUploadedButBillPending /> },
  { path: "/billing/pod-upload-but-mr-pending", component: <PoDUploadedButMRPending /> },
  { path: "/billing/billing-time-analysis", component: <BillingTimeAnalysis /> },
  { path: "/billing", component: <Billing /> },
  
  // //Credit Control Report
  { path: "/credit-control", component: <CreditControl /> },
  { path: "/branch-details", component: <CustomerReport /> },
  { path: "/customer-bills", component: <CustomerBillsPending /> },

  //Booking Dashboard
  { path: "/booking", component: <Booking /> },
  { path: "/booking/lrs-pending-dispatch", component: <LrPendingforDispatch /> },
  { path: "/booking/door-challan", component: <DoorChallan /> },
  { path: "/booking/lr-with-freight", component: <LrWithFreight /> },


  // Inward Dashbaord
  { path: "/inward", component: <Inward /> },
  { path: "/inward/lhs-pending-vehicle-arrival", component: <VehicleArrival />},
  { path: "/inward/lhs-pending-vehicle-unloading", component: <VehicleUnloading />},
  { path: "/inward/incoming-consignment-report", component: <IncomingConsignmentReport />},
  
  // Pod Dashboard
  { path: "/pod", component: <Pod />},
  { path: "/pod/Lr-pending-for-pod-billing", component: <LRPendingForPODBilling />},
  { path: "/pod/Lr-pending-for-pod", component: <LRPendingForPOD />},
  { path: "/pod/lr-pending-for-pod-ftl", component: <LRPendingForPODFTL />},
  { path: "/pod/lr-pending-for-pod-ftl-marketing", component: <LRPendingForPODFTLMarketing />},
  { path: "/pod/lr-pending-for-sundry", component: <LRPendingForPODSundry />},

  
  //Delivery Dashobard
  { path: "/delivery", component: <Delivery />},
  { path: "/delivery/pre-door-delivery-sheet", component: <PreDoorDeliverySheet />},
  { path: "/delivery/pdds-pending-for-door-delivery", component: <PDDSPendingForDoorDeliverySettlement/>},
  { path: "/delivery/lr-pending-for-gate-pass", component: <LRsPendingForGatePassCash/>},
  { path: "/delivery/lr-pending-for-bill-gate-pass", component: <LRsPendingForBillGatePass/>},
  { path: "/delivery/lr-pending-for-bank-memo-gate-pass", component: <LRsPendingForBankMemocumGatePass/>},
  { path: "/delivery/lr-pending-for-direct-door-delivery-from-branch", component: <LHSpendingforDirectDoorDeliveryFromBranch/>},
  { path: "/delivery/lr-pending-for-direct-door-delivery-to-branch", component: <LHSpendingforDirectDoorDeliveryToBranchCopy/>},

  

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  // 404 Page Not Found route
  {
    path: "*",
    component: <Pages404 />,
  },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  // { path: "/forgot-password", component: <ForgetPwd /> },
  // { path: "/register", component: <Register /> },

  { path: "/pages-500", component: <Pages500 /> },

  // Authentication Inner
  // { path: "/login", component: <Login1 /> },
  { path: "/register", component: <Register1 /> },
  { path: "/forgot-password", component: <Recoverpw /> },
  // { path: "/auth-lock-screen", component: <LockScreen /> },

  // 404 Page Not Found route
  {
    path: "*",
    component: <Pages404 />,
  },
]

export { userRoutes, authRoutes }