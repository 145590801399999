import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import '../../components/CommonForBoth/rightbar.scss';
import { Link } from "react-router-dom";
import Select from "react-select";
import { Label, Badge } from "reactstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getcustomerOutstandingFilterReq } from './CreditControlService';

const CreditFilterBar = ({ onApplyFilters, filters }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    regional_office: filters?.regional_office || [],
    area_office: filters?.area_office || [],
    collection_branch: filters?.collection_branch || [],
    party_name: filters?.party_name || [],
    marketing_exec: filters?.marketing_exec || [],
    collection_exec: filters?.collection_exec || [],
    bill_date: filters?.bill_date || null,
    due_date: filters?.due_date || null,
    bill_amount: filters?.bill_amount || '',
    overdue_days: filters?.overdue_days || []
  });

  const [regionalOffices, setRegionalOffices] = useState([]);
  const [areaOffices, setAreaOffices] = useState([]);
  const [collectionBranches, setCollectionBranches] = useState([]);
  const [partyNames, setPartyNames] = useState([]);
  const [marketingExecs, setMarketingExecs] = useState([]);
  const [collectionExecs, setCollectionExecs] = useState([]);
  const [appliedFilterCount, setAppliedFilterCount] = useState(0);

  const clearFilters = () => {
    setSelectedFilters({
      regional_office: [],
      area_office: [],
      collection_branch: [],
      party_name: [],
      marketing_executive: [],
      collection_executive: [],
      bill_date: null,
      due_date: null,
      bill_amount: '',
      overdue_days: []
    });
    onApplyFilters({});
    setAppliedFilterCount(0);
    setIsOpen(false);
  };

  const toggleFilterBar = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterChange = (name, selectedOptions) => {
    setSelectedFilters(prevState => ({
      ...prevState,
      [name]: selectedOptions || []
    }));
  };

  const handleDateChange = (name, date) => {
    setSelectedFilters(prevState => ({
      ...prevState,
      [name]: date
    }));
  };

  const handleInputChange = (name, value) => {
    setSelectedFilters(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setSelectedFilters((prevState) => {
      const overdue_days = checked
        ? [...prevState.overdue_days, value]
        : prevState.overdue_days.filter((day) => day !== value);
      return {
        ...prevState,
        overdue_days
      };
    });
  };

  const applyFilters = () => {
    const filteredFilters = Object.fromEntries(
      Object.entries(selectedFilters).filter(([_, value]) =>
        value !== '' && value !== null && (Array.isArray(value) ? value.length > 0 : value !== '')
      )
    );
    onApplyFilters(filteredFilters);
    setAppliedFilterCount(Object.values(filteredFilters).filter(filter => filter && filter.length > 0).length);
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await getcustomerOutstandingFilterReq();
        setRegionalOffices(response?.results?.regional_office || []);
        setAreaOffices(response?.results?.area_office || []);
        setCollectionBranches(response?.results?.collection_branch || []);
        setPartyNames(response?.results?.party_name || []);
        setMarketingExecs(response?.results?.marketing_executive || []);
        setCollectionExecs(response?.results?.collection_executive || []);
      } catch (error) {
        console.error('Error fetching filters:', error);
      }
    };
    fetchFilters();
  }, []);

  return (
    <div>
      {!isOpen && (
        <button
          className="btn btn-primary rounded-circle position-fixed"
          style={{ bottom: '25px', right: '20px', zIndex: 9999, width: '60px', height: '60px', fontSize: '24px', position: 'relative' }}
          onClick={toggleFilterBar}
        >
          <i className="mdi mdi-filter-variant" />
          {appliedFilterCount > 0 && (
            <Badge
              style={{
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                zIndex: 10000,
                width: '25px',
                height: '25px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px',
              }}
              color="danger"
              pill
            >
              {appliedFilterCount}
            </Badge>
          )}
        </button>
      )}
      <div
        className={`offcanvas offcanvas-end filter-sidebar ${isOpen ? 'show' : ''}`}
        style={{
          visibility: isOpen ? 'visible' : 'hidden',
          width: isOpen ? '300px' : '0px',
          transition: 'width 0.3s ease',
          zIndex: 9999,
        }}
      >
        <div>
          <SimpleBar style={{ height: '100vh' }}>
            <div data-simplebar className="h-100">
              <div className="rightbar-title px-3 py-4">
                <Link
                  to="#"
                  onClick={e => {
                    e.preventDefault();
                    toggleFilterBar();
                  }}
                  className="right-bar-toggle float-end"
                >
                  <i className="mdi mdi-close noti-icon" />
                </Link>
                <h5 className="offcanvas-title">Filters</h5>
              </div>
              <hr className="my-0" />
              <div className="offcanvas-body" style={{
                height: '100vh'
              }}>

                {/* Regional Office Filter */}
                <div className="mb-3">
                  <Label>Regional Office</Label>
                  <Select
                    value={selectedFilters.regional_office}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('regional_office', selectedOptions)}
                    options={regionalOffices.map(office => ({ value: office, label: office }))}
                    classNamePrefix="select2-selection"
                    isLoading={!regionalOffices.length}
                  />
                </div>

                {/* Area Office Filter */}
                <div className="mb-3">
                  <Label>Area Office</Label>
                  <Select
                    value={selectedFilters.area_office}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('area_office', selectedOptions)}
                    options={areaOffices.map(office => ({ value: office, label: office }))}
                    classNamePrefix="select2-selection"
                    isLoading={!areaOffices.length}
                  />
                </div>

                {/* Collection Branch Filter */}
                <div className="mb-3">
                  <Label>Collection Branch</Label>
                  <Select
                    value={selectedFilters.collection_branch}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('collection_branch', selectedOptions)}
                    options={collectionBranches.map(branch => ({ value: branch, label: branch }))}
                    classNamePrefix="select2-selection"
                    isLoading={!collectionBranches.length}
                  />
                </div>

                {/* Party Name Filter */}
                <div className="mb-3">
                  <Label>Party Name</Label>
                  <Select
                    value={selectedFilters.party_name}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('party_name', selectedOptions)}
                    options={partyNames.map(name => ({ value: name, label: name }))}
                    classNamePrefix="select2-selection"
                    isLoading={!partyNames.length}
                  />
                </div>

                {/* Marketing Executive Filter */}
                <div className="mb-3">
                  <Label>Marketing Executive</Label>
                  <Select
                    value={selectedFilters.marketing_exec}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('marketing_exec', selectedOptions)}
                    options={marketingExecs.map(exec => ({ value: exec, label: exec }))}
                    classNamePrefix="select2-selection"
                    isLoading={!marketingExecs.length}
                  />
                </div>

                {/* Collection Executive Filter */}
                <div className="mb-3">
                  <Label>Collection Executive</Label>
                  <Select
                    value={selectedFilters.collection_exec}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('collection_exec', selectedOptions)}
                    options={collectionExecs.map(exec => ({ value: exec, label: exec }))}
                    classNamePrefix="select2-selection"
                    isLoading={!collectionExecs.length}
                  />
                </div>

                {/* Bill Date Filter */}
                <div className="mb-3">
                  <Label>Bill Date</Label>
                  <DatePicker
                    selected={selectedFilters.bill_date}
                    onChange={(date) => handleDateChange('bill_date', date)}
                    className="form-control"
                    placeholderText="Select Bill Date"
                  />
                </div>

                {/* Due Date Filter */}
                <div className="mb-3">
                  <Label>Due Date</Label>
                  <DatePicker
                    selected={selectedFilters.due_date}
                    onChange={(date) => handleDateChange('due_date', date)}
                    className="form-control"
                    placeholderText="Select Due Date"
                  />
                </div>

                {/* Bill Amount Filter */}
                <div className="mb-3">
                  <Label>Bill Amount</Label>
                  <input
                    type="number"
                    className="form-control"
                    value={selectedFilters.bill_amount}
                    onChange={(e) => handleInputChange('bill_amount', e.target.value)}
                    placeholder="Enter Bill Amount"
                  />
                </div>

                {/* Overdue Days Filter */}
                <div className="mb-3">
                  <Label>Overdue Days</Label>
                  <div className="d-flex flex-wrap">
                    {['0-30', '31-90', '91-180', '181-99999'].map(range => (
                      <div key={range} className="form-check me-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="overdue_days"
                          value={range}
                          checked={selectedFilters.overdue_days.includes(range)}
                          onChange={handleCheckboxChange}
                        />
                        <label className="form-check-label">{range}</label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="d-flex justify-content-around">
                  <button className="btn btn-secondary" onClick={clearFilters}>
                    Clear All Filters
                  </button>
                  <button className="btn btn-primary" onClick={applyFilters}>
                    Apply Filters
                  </button>
                </div>
              </div>
            </div>
          </SimpleBar>
        </div>
      </div>
      {
        isOpen && (
          <div
            className="rightbar-overlay"
            onClick={toggleFilterBar}
          />
        )
      }
    </div>
  );
};

CreditFilterBar.propTypes = {
  onApplyFilters: PropTypes.func.isRequired,
};

export default CreditFilterBar;
