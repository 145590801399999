export const createQueryParamsFromBreadcrumbs = (breadcrumbs) => {
    if (!breadcrumbs) return {};  // Return an empty object if breadcrumbs is undefined or null
    
    return breadcrumbs.reduce((params, crumb) => {
      if (crumb.field) {
        params[crumb.field] = crumb.label;
      }
      return params;
    }, {});
  };
  

  export const calculateTotalRow = (data, columns) => {
    return data.reduce(
      (totals, row) => {
        columns.forEach(col => {
          if (typeof row[col] === 'number' && col !== columns[0]) {
            totals[col] = (totals[col] || 0) + row[col];
          }
        });
        return totals;
      },
      {}
    );
  };

  export const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  export const formatNumber = (num) => {
    if (typeof num !== 'number') return num;
    return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };


// Helper functions to lighten or darken the color dynamically
export const lightenColor = (color, amount) => {
  const [r, g, b] = hexToRgb(color);
  return rgbToHex(Math.min(255, r + amount * 255), Math.min(255, g + amount * 255), Math.min(255, b + amount * 255));
}

export const darkenColor = (color, amount) => {
  const [r, g, b] = hexToRgb(color);
  return rgbToHex(Math.max(0, r - amount * 255), Math.max(0, g - amount * 255), Math.max(0, b - amount * 255));
}

// Convert hex to RGB
function hexToRgb(hex) {

  if (!/^#([0-9A-F]{3}){1,2}$/i.test(hex)) {
    throw new Error('Invalid hex color format');
  }

  if (hex.length === 4) {
    hex = `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
  }

  const bigint = parseInt(hex.slice(1), 16);
  return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
}


// Convert RGB to hex
function rgbToHex(r, g, b) {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
}
