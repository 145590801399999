import React , {useEffect,useRef} from "react"
import { connect } from "react-redux";
import DrillDownTable from "components/CustomComponents/DrillDownTable";
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { Card,ButtonDropdown,DropdownToggle,DropdownMenu,DropdownItem,CardTitle } from "reactstrap";
import { useState } from "react";
import { getbillingCommonFilterReq, getBillsPendingApprovalReq, getbillsPendingApprovalReq, getfreightBillsHierarchicalReq, getfreightBillsReq, getbillingSummaryReq } from "./billingService";
import { getdeliveredLrsUnbilledReportReq, getDeliveredLrsUnbilledReportReq } from "./billingService";
import { getPoDUploadedButBillPendingReq, getpodUploadedReceivedBillPendingReq } from "./billingService";
import { getPoDUploadedButMRPendingReq, getpodUploadedMrPendingReq } from "./billingService";
import { gettotalUnbilledHierarchicalReq, gettotalUnbilledreportReq } from "./billingService";
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import BillingFilterBar from "./BillingFilterBar";
import SummaryCards from "components/CustomComponents/SummaryCards";
import BillingDrill from "components/CustomComponents/BillingDrill";
const Billing = (props) => {

  document.title = "Aurum | Billing";
  const [shareBtnToggle, setshareBtnToggle] = useState(false);
  const [dwnBtnToggle, setdwnBtnToggle] = useState(false);
  const navigate = useNavigate();
  const tableRefs = useRef([]);
  const [filters, setFilters] = useState({});

  const breadcrumbItems = [
    { title: "Home", link: "/dashboard" },
    { title: "Billing", link: "/billing" },
  ]

  // Card titles to use for sheet names
  const cardTitles = [
    "Bills Pending Approval",
    "Delivered LRs Unbilled Report",
    "PoD Uploaded But Bill Pending",
    "PoD Uploaded But MR Pending",
    "Total Unbilled Report"
  ];

  const handleCardTitleClick = (destinationPage) => (event) => {
    event.stopPropagation();
    navigate(destinationPage);
  };

  useEffect(() => {
    props.setBreadcrumbItems('Billing' , breadcrumbItems);
  },)

  const applyFilters = (filters) => {
    // console.log("Applied Filters:", filters);
    setFilters(filters || {});
  };


  const downloadPDF = () => {}


const downloadExcel = () => {
    const wb = XLSX.utils.book_new();

    if (!Array.isArray(tableRefs.current) || tableRefs.current.length === 0) {
      console.error('No table references available to export.');
      return;
    }

    // Loop through table references and use card titles to name sheets
    tableRefs.current.forEach((ref, index) => {
      const table = ref;
      if (table && table.rows.length > 0) {
        const ws = XLSX.utils.table_to_sheet(table);
        const sheetName = cardTitles[index] || `Sheet${index + 1}`; // Use card title or default sheet name
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
      } else {
        console.warn(`Table reference at index ${index} is not available or has no rows.`);
      }
    });

    if (wb.SheetNames.length === 0) {
      console.error('No data to export; workbook is empty.');
      return;
    }

    XLSX.writeFile(wb, 'BillingData.xlsx');
  };
  
  return (
    <React.Fragment >
      <SummaryCards fetchDataFn={getbillingSummaryReq} />
        {/* <div style={{ position: "relative" }}>
            <div
            style={{
                position: "absolute",
                top: -150,
                right: 10,
                display: "flex",
            }}
            >
              <ButtonDropdown
                isOpen={dwnBtnToggle}
                toggle={() => {
                  setdwnBtnToggle(!dwnBtnToggle);
                }}
              >
                <DropdownToggle caret className="btn btn-primary w-m mx-2">
                  <i className="fas fa-download me-2"></i> Download <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={downloadPDF}>
                    <i className="fas fa-file-pdf me-2"></i> PDF
                  </DropdownItem>
                  <DropdownItem onClick={downloadExcel}>
                    <i className="fas fa-file-excel me-2"></i> Excel
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
              <ButtonDropdown
                  isOpen={shareBtnToggle}
                  toggle={() => {
                      setshareBtnToggle(!shareBtnToggle)
                  }}
              >
                  <DropdownToggle caret className="btn btn-primary w-m mx-2 ">
                  <i className="fas fa-share me-2"></i> Share Via <i className="mdi mdi-chevron-down"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                      <DropdownItem>Email</DropdownItem>
                      <DropdownItem>Whatsapp</DropdownItem>
                  </DropdownMenu>
              </ButtonDropdown>
            </div>
        </div> */}
        <div style={{ 
          display: 'flex', 
          flexWrap: 'wrap', 
          gap: '12px' 
        }}>
          <BillingFilterBar onApplyFilters={applyFilters} filters={filters}/>
          <Card body style={{ flex:' 1 1 calc(50% - 12px)', boxSizing: 'border-box', minHeight: '300px'}} className="overflow-hidden">
            <CardTitle onClick={handleCardTitleClick("")} style={{ cursor:"pointer"}} className="h4">Billing Time Analysis</CardTitle>
            <DrillDownTable 
              ref={el => tableRefs.current[1] = el}
              fetchDataFn={null} 
              fetchDataAtBreadcrumbLimit={null}  
              destinationPage={"#"}
              color="#C4F1F9"
              initialQueryParams={filters}
            />
          </Card>
          <Card body style={{ flex:' 1 1 calc(50% - 12px)', boxSizing: 'border-box', minHeight: '300px' }} className="overflow-hidden">
          <CardTitle onClick={handleCardTitleClick("bills-pending-approval")} style={{ cursor:"pointer"}} className="h4">Bills Pending Approval</CardTitle>
          <BillingDrill
              uniqueKey="bills-pending-approval"
              ref={el => tableRefs.current[0] = el}
              fetchDataFn={getBillsPendingApprovalReq} 
              fetchDataAtBreadcrumbLimit={getbillsPendingApprovalReq}  
              destinationPage={"bills-pending-approval"}
              color="#D0E6F1"
            />
          </Card>
          {/* <Card body style={{ flex:' 1 1 calc(50% - 12px)', boxSizing: 'border-box', minHeight: '300px' }} className="overflow-hidden">
          <CardTitle onClick={handleCardTitleClick("bills-pending-approval")} style={{ cursor:"pointer"}} className="h4">Bills Pending Approval</CardTitle>
          <DrillDownTable 
              ref={el => tableRefs.current[0] = el}
              fetchDataFn={getBillsPendingApprovalReq} 
              fetchDataAtBreadcrumbLimit={getbillsPendingApprovalReq}  
              destinationPage={"bills-pending-approval"}
              color="#D0E6F1"
              initialQueryParams={filters}
            />
          </Card> */}
          <div style={{ flex: '1 1 calc(50% - 12px)', boxSizing: 'border-box' }}>
            <Card  body className="overflow-hidden" style={{minHeight: '300px'}}>
            <CardTitle onClick={handleCardTitleClick("pod-uploaded-but-bill-pending")} style={{ cursor:"pointer"}} className="h4">PoD Uploaded But Bill Pending</CardTitle>
              <DrillDownTable 
                ref={el => tableRefs.current[2] = el}
                fetchDataFn={getPoDUploadedButBillPendingReq} 
                fetchDataAtBreadcrumbLimit={getpodUploadedReceivedBillPendingReq} 
                destinationPage={"pod-uploaded-but-bill-pending"}
                color="#D5F6E3"
                initialQueryParams={filters}
              />
            </Card>
          </div>
          <div style={{ flex: '1 1 calc(50% - 12px)', boxSizing: 'border-box' }}>
            <Card body className="overflow-hidden" style={{minHeight: '300px'}}>
            <CardTitle onClick={handleCardTitleClick("pod-upload-but-mr-pending")} style={{ cursor:"pointer"}} className="h4">PoD Uploaded But MR Pending</CardTitle>
              <DrillDownTable 
                ref={el => tableRefs.current[3] = el}
                fetchDataFn={getPoDUploadedButMRPendingReq} 
                fetchDataAtBreadcrumbLimit={getpodUploadedMrPendingReq} 
                destinationPage={"pod-upload-but-mr-pending"}
                color="#F9E6C2"
                initialQueryParams={filters}
              />
            </Card>
          </div>
          <div style={{ flex: '1 1 calc(50% - 12px)', boxSizing: 'border-box' }}>
            <Card body className="overflow-hidden" style={{minHeight: '300px'}}>
            <CardTitle onClick={handleCardTitleClick("delivered-lrs-unbilled-report")} style={{ cursor:"pointer"}} className="h4">Delivered LRs Unbilled Report</CardTitle>
              <DrillDownTable 
                ref={el => tableRefs.current[1] = el}
                fetchDataFn={getDeliveredLrsUnbilledReportReq} 
                fetchDataAtBreadcrumbLimit={getdeliveredLrsUnbilledReportReq} 
                destinationPage={"delivered-lrs-unbilled-report"}
                color="#E6D3F3"
                initialQueryParams={filters}
              />
            </Card>
          </div>
          <div style={{ flex: '1 1 calc(50% - 12px)', boxSizing: 'border-box' }}>
            <Card body className="overflow-hidden" style={{minHeight: '300px',}}>
            <CardTitle onClick={handleCardTitleClick("total-unbilled-report")} style={{ cursor:"pointer"}} className="h4">Total Unbilled Report</CardTitle>
              <DrillDownTable 
                ref={el => tableRefs.current[4] = el}
                fetchDataFn={gettotalUnbilledHierarchicalReq} 
                fetchDataAtBreadcrumbLimit={gettotalUnbilledreportReq}
                destinationPage={"total-unbilled-report"}
                color="#FFCCCB"
                initialQueryParams={filters}
              />
            </Card>
          </div>
      </div>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Billing);