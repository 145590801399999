import React, { useEffect, useRef } from "react"
import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { Card, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle } from "reactstrap";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

import SummaryCards from "components/CustomComponents/SummaryCards";
import BookingFilterBar from "./BookingFilterBar";
import { getbookingSummaryReq, getlrFreightBookingwiseHierarchicalReq, getlrFreightBookingwiseReq, getlrPendingDispatchReq, getlrPendingForDispatchHierarchicalReq, getlrPendingForDoorChallenHierarchicalReq, getlrPendingReq } from "./bookingService";
import DrillDownTable from "components/CustomComponents/DrillDownTable";

const Booking = (props) => {

  document.title = "Aurum | Booking";
  const navigate = useNavigate();
  const tableRefs = useRef([]);
  const [filters, setFilters] = useState({});

  const breadcrumbItems = [
    { title: "Home", link: "/dashboard" },
    { title: "Booking", link: "/booking" },
  ]


  const handleCardTitleClick = (destinationPage) => (event) => {
    event.stopPropagation();
    navigate(destinationPage);
  };

  useEffect(() => {
    props.setBreadcrumbItems('Booking', breadcrumbItems);
  },)

  const applyFilters = (filters) => {
    // console.log("Applied Filters:", filters);
    setFilters(filters || {});
  };

  return (
    <React.Fragment >
      <SummaryCards fetchDataFn={getbookingSummaryReq} />
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '12px'
      }}>
        <BookingFilterBar onApplyFilters={applyFilters} filters={filters}/>
        <Card body style={{ flex: ' 1 1 calc(50% - 12px)', boxSizing: 'border-box', minHeight: '300px' }} className="overflow-hidden">
          <CardTitle onClick={handleCardTitleClick("lrs-pending-dispatch")} style={{ cursor: "pointer" }} className="h4">LRs Pending for Dispatch (Current Branch Wise)</CardTitle>
          <DrillDownTable
            ref={el => tableRefs.current[0] = el}
            fetchDataFn={getlrPendingForDispatchHierarchicalReq}
            fetchDataAtBreadcrumbLimit={getlrPendingDispatchReq}
            destinationPage={"lrs-pending-dispatch"}
            initialQueryParams={filters}
            color='#FBE7C4'
          />
        </Card>
        <Card body style={{ flex: ' 1 1 calc(50% - 12px)', boxSizing: 'border-box', minHeight: '300px' }} className="overflow-hidden">
          <CardTitle onClick={handleCardTitleClick("door-challan")} style={{ cursor: "pointer" }} className="h4">LRs Pending for Door Collection Challan (Booking Branch Wise)</CardTitle>
          <DrillDownTable
            ref={el => tableRefs.current[1] = el}
            fetchDataFn={getlrPendingForDoorChallenHierarchicalReq}
            fetchDataAtBreadcrumbLimit={getlrPendingReq}
            destinationPage={"door-challan"}
            initialQueryParams={filters}
            color='#C8E4F3'
          />
        </Card>
        <Card body style={{ flex: ' 1 1 calc(50% - 12px)', boxSizing: 'border-box', minHeight: '300px' }} className="overflow-hidden">
          <CardTitle onClick={handleCardTitleClick("lr-with-freight")} style={{ cursor: "pointer" }} className="h4">LRs with Freight as Re. 1 divided in sundry & FTL (Booking Branch Wise)</CardTitle>
          <DrillDownTable
            ref={el => tableRefs.current[2] = el}
            fetchDataFn={getlrFreightBookingwiseHierarchicalReq}
            fetchDataAtBreadcrumbLimit={getlrFreightBookingwiseReq}
            destinationPage={"lr-with-freight"}
            initialQueryParams={filters}
            color='#D6F7D8'
          />
        </Card>
      </div>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Booking);