export const columnLabelDict = {
    zonal_office: 'Zonal Office',
    lr_count: "LRs",
    packet_sum: 'Total Packets',
    actual_weight_sum: 'Total Actual Weight',
    charged_weight_sum: 'Total Charged Weight',
    basic_freight_sum: 'Total Basic Freight',
    total_freight_sum: 'Total Freight',
    regional_office: 'Regional Office',
    area_office: 'Area Office',
    billing_branch: 'Billing Branch',
    lr_date: 'LR Date',
    lr_no: 'LR No.',
    load_type: 'Load Type',
    consignor_name: "Consignor Name",
    consignee_name: "Consignee Name",
    billing_party: "Billing Party",
    physical_pod: "Physical PoD",
    pod_branch: "PoD Branch",
    pod_update_date: "PoD Upload Date",
  };

  export const ColumnLabelDictBooking ={
    AreaOffice: 'From Area Office',
    RegionalOffice: 'From Regional Office',
    ZonalOffice: 'From Zonal Office',
    BookingLocation: "From Branch Name",
    CurrentBranch: "Current Branch",
    LrNo: "LR No",
    LrDate: "LR Date",	
    BookingBranch: "Booking Branch",	
    ToBranch: "To Branch",	
    BookingType: "Booking Type",	
    StockType: "Stock Type",	
    ConsignorName: "Consignor Name",	
    ConsigneeName: "Consignee Name",	
    NoofPkgs: "Packet",	
    ItemName: "Item Name",	
    ActualWeight: "Actual Weight",	
    ChargedWeight: "Charged Weight",	
    BasicFreight: "Basic Freight",
    TotalFreight: "Total Freight",	
    LastUnloadNo: "Last Unload No",	
    LastUnloadDate: "Last Unload Date",
    lr_count: "LR",
    packet_count: "Packets",
    total_actual_weight: 'Total Actual Weight',
    total_charged_weight: 'Total Charged Weight',
    total_basic_freight: 'Total Basic Freight',
    total_freight: 'Total Freight',
  }

  export const summarylabelDict = {
    bill_amount_sum: 'Bill Amount',
    on_account_sum: 'On Account',
    balance_amount_sum: 'Balance Amount',
    days_0_30_sum: '0 - 30 Days',
    due_amount_sum: 'Due Amount',
    days_31_60_sum: '31 - 60 Days',
    days_61_90_sum: '61 - 90 Days',
    days_91_180_sum: '91 - 180 Days',
    days_over_181_sum: '180+ Days',
    bills_pending_approval_count: 'Bills Pending Approval',
    mr_pending_lr_count: 'PoD Uploaded/Received but MR Pending LRs',
    delivered_lr_unbilled_count : 'Delivered LRs Unbilled',
    bill_pending_lr_count: 'PoD Uploaded/Received but Bill pending LRs',
    total_unbilled_lr_count : 'Total Unbilled LR',
    LRPendingForDoorChallen: "LR Pending For Door Challan",
    LRPendingForDispatch: "LR Pending For Dispatch",
  }