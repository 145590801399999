import { summarylabelDict } from "pages/Billing/Constants/contants";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";

const SummaryCards = ({ fetchDataFn }) => {
  const [summaryData, setSummaryData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchDataFn();
        setSummaryData(data?.results);
      } catch (error) {
        console.error("Error fetching summary data:", error);
      }
    };

    fetchData();
  }, [fetchDataFn]);

  return (
    <Card style={{ 
      width: '100%', 
      borderRadius: '10px', 
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', 
      // backgroundColor: '#f8f9fa',
      padding: '10px'
    }}>
      <CardBody style={{ 
        display: 'flex', 
        justifyContent: 'space-around', 
        alignItems: 'center', 
        padding: 0 
      }}>
        {Object.entries(summaryData).map(([key, value], index, arr) => (
          <div key={key} style={{
            flex: 1,
            textAlign: 'center',
            padding: '0 10px',
            borderRight: index < arr.length - 1 ? '1px solid #343a40' : 'none' // Add vertical line except for the last item
          }}>
            <CardTitle className="h6" style={{ 
              color: '#343a40', 
              fontSize: '0.9rem', 
              textAlign: 'center', 
              // whiteSpace: 'nowrap', 
              maxWidth: '100%' 
            }}>
              {summarylabelDict[key] || key.replace(/_/g, ' ').toUpperCase()}
            </CardTitle>
            <h5 style={{ 
              color: '#007bff', 
              fontSize: '1rem', 
              textAlign: 'center', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis', 
              whiteSpace: 'nowrap', 
              maxWidth: '100%' 
            }}>
              {value}
            </h5>
          </div>
        ))}
      </CardBody>
    </Card>
  );
};

export default SummaryCards;
