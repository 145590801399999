import React, { useEffect, useRef } from "react"
import { connect } from "react-redux";
import DrillDownTable from "components/CustomComponents/DrillDownTable";
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { Card, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle } from "reactstrap";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import SummaryCards from "components/CustomComponents/SummaryCards";
import { getFtlBillingWiseHierarchicalReq, getFtlBillingWiseReq, getFtlMarketingExecutiveHierarchicalReq, getFtlMarketingExecutiveReq, getFtlTrafficInchargeHierarchicalReq, getFtlTrafficInchargeReq, getPodSummaryReq, getSundryMarketingExecutiveHierarchicalReq, getSundryMarketingExecutiveReq, getSundryWiseHierarchicalReq, getSundryWiseReq } from "./podService";
import { getFtlBillingWise, getSundryMarketingExecutiveHierarchical } from "api";
import DrillDownTablec from "components/CustomComponents/DrillDownTablec";
import PodFilterBar from "./PodFilterBar";
const Pod = (props) => {

  document.title = "Aurum | Pod";
  const [shareBtnToggle, setshareBtnToggle] = useState(false);
  const [dwnBtnToggle, setdwnBtnToggle] = useState(false);
  const navigate = useNavigate();
  const tableRefs = useRef([]);
  const [filters, setFilters] = useState({});

  const breadcrumbItems = [
    { title: "Home", link: "/dashboard" },
    { title: "Pod", link: "/pod" },
  ]

  const handleCardTitleClick = (destinationPage) => (event) => {
    event.stopPropagation();
    navigate(destinationPage);
  };

  useEffect(() => {
    props.setBreadcrumbItems('Pod', breadcrumbItems);
  },)

  const applyFilters = (filters) => {
    // console.log("Applied Filters:", filters);
    setFilters(filters || {});
  };


  const downloadPDF = () => { }


  const downloadExcel = () => {
  };

  return (
    <React.Fragment >
      <SummaryCards fetchDataFn={getPodSummaryReq} />
      {/* <div style={{ position: "relative" }}>
            <div
            style={{
                position: "absolute",
                top: -150,
                right: 10,
                display: "flex",
            }}
            >
              <ButtonDropdown
                isOpen={dwnBtnToggle}
                toggle={() => {
                  setdwnBtnToggle(!dwnBtnToggle);
                }}
              >
                <DropdownToggle caret className="btn btn-primary w-m mx-2">
                  <i className="fas fa-download me-2"></i> Download <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={downloadPDF}>
                    <i className="fas fa-file-pdf me-2"></i> PDF
                  </DropdownItem>
                  <DropdownItem onClick={downloadExcel}>
                    <i className="fas fa-file-excel me-2"></i> Excel
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
              <ButtonDropdown
                  isOpen={shareBtnToggle}
                  toggle={() => {
                      setshareBtnToggle(!shareBtnToggle)
                  }}
              >
                  <DropdownToggle caret className="btn btn-primary w-m mx-2 ">
                  <i className="fas fa-share me-2"></i> Share Via <i className="mdi mdi-chevron-down"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                      <DropdownItem>Email</DropdownItem>
                      <DropdownItem>Whatsapp</DropdownItem>
                  </DropdownMenu>
              </ButtonDropdown>
            </div>
        </div> */}
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '12px'
      }}>
        <PodFilterBar onApplyFilters={applyFilters} filters={filters}/>
        <Card body style={{ flex: ' 1 1 calc(50% - 12px)', boxSizing: 'border-box', minHeight: '300px' }} className="overflow-hidden">
          <CardTitle onClick={handleCardTitleClick("lr-pending-for-sundry")} style={{ cursor: "pointer" }} className="h4">LR Pending For POD ( Sundry) - Marketing Exec Wise		</CardTitle>
          <DrillDownTablec
            ref={el => tableRefs.current[0] = el}
            fetchDataFn={getSundryMarketingExecutiveHierarchicalReq}
            fetchDataAtBreadcrumbLimit={getSundryMarketingExecutiveReq}
            destinationPage={"lr-pending-for-sundry"}
            limit={1}
            initialQueryParams={filters}
            color='#BFE0F5'

          />
        </Card>
        <Card body className="overflow-hidden" style={{ minHeight: '300px' }}>
          <CardTitle onClick={handleCardTitleClick("lr-pending-for-pod-ftl")} style={{ cursor: "pointer" }} className="h4">LR Pending For POD (FTL) - Traffic Exec Wise</CardTitle>
          <DrillDownTablec
            ref={el => tableRefs.current[3] = el}
            fetchDataFn={getFtlTrafficInchargeHierarchicalReq}
            fetchDataAtBreadcrumbLimit={getFtlTrafficInchargeReq}
            destinationPage={"lr-pending-for-pod-ftl"}
            limit={1}
            initialQueryParams={filters}
            color='#FDECBF'
          />
        </Card>
        <div style={{ flex: '1 1 calc(50% - 12px)', boxSizing: 'border-box' }}>
          <Card body style={{ flex: ' 1 1 calc(50% - 12px)', boxSizing: 'border-box', minHeight: '300px' }} className="overflow-hidden">
            <CardTitle onClick={handleCardTitleClick("Lr-pending-for-pod")} style={{ cursor: "pointer" }} className="h4">LR Pending For POD (Sundry)</CardTitle>
            <DrillDownTable
              ref={el => tableRefs.current[1] = el}
              fetchDataFn={getSundryWiseHierarchicalReq}
              fetchDataAtBreadcrumbLimit={getSundryWiseReq}
              destinationPage={"Lr-pending-for-pod"}
              initialQueryParams={filters}
              color='#D8F7C8'
            />
          </Card>
        </div>
        <div style={{ flex: '1 1 calc(50% - 12px)', boxSizing: 'border-box' }}>
          <Card body className="overflow-hidden" style={{ minHeight: '300px' }}>
            <CardTitle onClick={handleCardTitleClick("Lr-pending-for-pod-billing")} style={{ cursor: "pointer" }} className="h4">LR Pending For POD (FTL) - Billing Branch Wise</CardTitle>
            <DrillDownTable
              ref={el => tableRefs.current[2] = el}
              fetchDataFn={getFtlBillingWiseHierarchicalReq}
              fetchDataAtBreadcrumbLimit={getFtlBillingWiseReq}
              destinationPage={"Lr-pending-for-pod-billing"}
              color='#F6D1E5'
              initialQueryParams={filters}
            />
          </Card>
        </div>
        <div style={{ flex: '1 1 calc(50% - 12px)', boxSizing: 'border-box' }}>
          <Card body className="overflow-hidden" style={{ minHeight: '300px' }}>
            <CardTitle onClick={handleCardTitleClick("lr-pending-for-pod-ftl-marketing")} style={{ cursor: "pointer" }} className="h4">LR Pending For POD (FTL) - Marketing Exec Wise</CardTitle>
            <DrillDownTablec
              ref={el => tableRefs.current[1] = el}
              fetchDataFn={getFtlMarketingExecutiveHierarchicalReq}
              fetchDataAtBreadcrumbLimit={getFtlMarketingExecutiveReq}
              destinationPage={"lr-pending-for-pod-ftl-marketing"}
              initialQueryParams={filters}
              limit={1}
              color='#FFDED1'
            />
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Pod);