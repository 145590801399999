import React, { useEffect, useRef } from "react"
import DrillDownTable from "components/CustomComponents/DrillDownTable";
import { connect } from "react-redux";
import {
  Card,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown
} from "reactstrap";
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { useState } from "react";
import generatePDF from "react-to-pdf";
import { options } from "utils/pdfOptions";
import { useLocation } from "react-router-dom";
import * as XLSX from 'xlsx';
import { getFtlTrafficInchargeHierarchicalReq, getFtlTrafficInchargeReq } from "./podService";
import DrillDownTablec from "components/CustomComponents/DrillDownTablec";
import PodFilterBar from "./PodFilterBar";

const LRPendingForPODFTL = (props) => {

  document.title = "Aurum | Dashboard";

  const location = useLocation();
  const breadcrumbs = location.state?.breadcrumbsState || [{ label: 'Home', field: '' }];
  const color = location.state?.color || "#D0E6F1";
  const queryParams = location.state?.queryParams || {};
  const [shareBtnToggle, setshareBtnToggle] = useState(false);
  const [dwnBtnToggle, setdwnBtnToggle] = useState(false);
  const tableRef = useRef(null);
  const [filters, setFilters] = useState({ ...queryParams });


  const breadcrumbItems = [
    { title: "Home", link: "/dashboard" },
    { title: "pod", link: "/pod" },
    { title: "LR Pending For POD ( Sundry)", link: "#" }
  ]

  useEffect(() => {
    props.setBreadcrumbItems('LR Pending For POD ( Sundry)', breadcrumbItems)
  },)

  const getTargetElement = () => document.getElementById("pdf-container");

  const downloadPDF = () => generatePDF(getTargetElement, options);

  const downloadExcel = () => {
    const wb = XLSX.utils.book_new();

    if (!tableRef.current || tableRef.current.rows.length === 0) {
      console.error('No table reference available to export.');
      return;
    }

    const ws = XLSX.utils.table_to_sheet(tableRef.current);
    XLSX.utils.book_append_sheet(wb, ws, "Bills Pending Approval"); // Use document title for the sheet name

    if (wb.SheetNames.length === 0) {
      console.error('No data to export; workbook is empty.');
      return;
    }

    XLSX.writeFile(wb, "Bills Pending Approval.xlsx"); // Use document title for the file name
  };

  const applyFilters = (filters) => {
    setFilters(filters || {});
  };

  return (
    <React.Fragment>
      {/* <div style={{ position: "relative" }}>
            <div
            style={{
                position: "absolute",
                top: -50,
                right: 10,
                display: "flex",
            }}
            >
              <ButtonDropdown
                isOpen={dwnBtnToggle}
                toggle={() => {
                  setdwnBtnToggle(!dwnBtnToggle);
                }}
              >
                <DropdownToggle caret className="btn btn-primary w-m mx-2">
                  <i className="fas fa-download me-2"></i> Download <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={downloadPDF}>
                    <i className="fas fa-file-pdf me-2"></i> PDF
                  </DropdownItem>
                  <DropdownItem onClick={downloadExcel}>
                    <i className="fas fa-file-excel me-2"></i> Excel
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
              <ButtonDropdown
                  isOpen={shareBtnToggle}
                  toggle={() => {
                      setshareBtnToggle(!shareBtnToggle)
                  }}
              >
                  <DropdownToggle caret className="btn btn-primary w-m mx-2 ">
                  <i className="fas fa-share me-2"></i> Share Via <i className="mdi mdi-chevron-down"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                      <DropdownItem>Email</DropdownItem>
                      <DropdownItem>Whatsapp</DropdownItem>
                  </DropdownMenu>
              </ButtonDropdown>
            </div>
        </div> */}
      <PodFilterBar onApplyFilters={applyFilters} filters={filters} />
      <Card body id="pdf-container" className=" overflow-hidden">
        <DrillDownTablec
          ref={tableRef}
          initialBreadcrumbs={breadcrumbs}
          fetchDataFn={getFtlTrafficInchargeHierarchicalReq}
          fetchDataAtBreadcrumbLimit={getFtlTrafficInchargeReq}
          initialQueryParams={filters}
          limit={1}
          color='#FDECBF'

        />
      </Card>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(LRPendingForPODFTL);