import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import '../../components/CommonForBoth/rightbar.scss';
import { Link } from "react-router-dom";
import Select from "react-select";
import { Label, Badge } from "reactstrap";
import { getInwardCommonFilterReq } from './inwardService';

const InwardFilterBar = ({ onApplyFilters, filters }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    from_location: filters?.from_location || [],
    load_type: filters?.load_type || [],
    to_location: filters?.to_location || [],
    lr_date: filters?.lr_date || [],
    lhs_date: filters?.lhs_date || [],
    vehicle_no: filters?.vehicle_no || []
  });

  const [fromLocations, setFromLocations] = useState([]);
  const [loadTypes, setLoadTypes] = useState([]);
  const [toLocations, setToLocations] = useState([]);
  const [lrDates, setLrDates] = useState([]);
  const [lhsDates, setLhsDates] = useState([]);
  const [vehicleNos, setVehicleNos] = useState([]);
  const [appliedFilterCount, setAppliedFilterCount] = useState(0);

  const clearFilters = () => {
    setSelectedFilters({
      from_location: [],
      load_type: [],
      to_location: [],
      lr_date: [],
      lhs_date: [],
      vehicle_no: []
    });
    onApplyFilters({});
    setAppliedFilterCount(0);
    setIsOpen(false);
  };

  const toggleFilterBar = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterChange = (name, selectedOptions) => {
    setSelectedFilters(prevState => ({
      ...prevState,
      [name]: selectedOptions || []
    }));
  };

  const applyFilters = () => {
    const filteredFilters = Object.fromEntries(
      Object.entries(selectedFilters)
        .filter(([_, value]) => value.length > 0)
        .map(([key, values]) => [
          key+"__in",
          values.map(option => option.value).join(',')
        ])
    );
    onApplyFilters(filteredFilters);
    setAppliedFilterCount(Object.values(selectedFilters).filter(filter => filter.length > 0).length);
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await getInwardCommonFilterReq();
        setFromLocations(response?.results?.from_location || []);
        setLoadTypes(response?.results?.load_type || []);
        setToLocations(response?.results?.to_location || []);
        setLrDates(response?.results?.lr_date || []);
        setLhsDates(response?.results?.lhs_date || []);
        setVehicleNos(response?.results?.vehicle_no || []);
      } catch (error) {
        console.error('Error fetching filters:', error);
      }
    };
    fetchFilters();
  }, []);

  return (
    <div>
      {!isOpen && (
        <button
          className="btn btn-primary rounded-circle position-fixed"
          style={{ bottom: '25px', right: '20px', zIndex: 9999, width: '60px', height: '60px', fontSize: '24px', position: 'relative' }}
          onClick={toggleFilterBar}
        >
          <i className="mdi mdi-filter-variant" />
          {appliedFilterCount > 0 && (
            <Badge
              style={{
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                zIndex: 10000,
                width: '25px',
                height: '25px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px',
              }}
              color="danger"
              pill
            >
              {appliedFilterCount}
            </Badge>
          )}

        </button>
      )}
      <div
        className={`offcanvas offcanvas-end filter-sidebar ${isOpen ? 'show' : ''}`}
        style={{
          visibility: isOpen ? 'visible' : 'hidden',
          width: isOpen ? '300px' : '0px',
          transition: 'width 0.3s ease',
          zIndex: 9999,
        }}
      >
        <div>
          <SimpleBar style={{ height: '100vh' }}>
            <div data-simplebar className="h-100">
              <div className="rightbar-title px-3 py-4">
                <Link
                  to="#"
                  onClick={e => {
                    e.preventDefault();
                    toggleFilterBar();
                  }}
                  className="right-bar-toggle float-end"
                >
                  <i className="mdi mdi-close noti-icon" />
                </Link>
                <h5 className="offcanvas-title">Filters</h5>
              </div>
              <hr className="my-0" />
              <div className="offcanvas-body" style={{
                height: '100vh'
              }}>

                {/* From Location Filter */}
                <div className="mb-3">
                  <Label>From Location</Label>
                  <Select
                    value={selectedFilters.from_location}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('from_location', selectedOptions)}
                    options={fromLocations.map(location => ({ value: location, label: location }))}
                    classNamePrefix="select2-selection"
                    isLoading={!fromLocations.length}
                  />
                </div>

                {/* Load Type Filter */}
                <div className="mb-3">
                  <Label>Load Type</Label>
                  <Select
                    value={selectedFilters.load_type}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('load_type', selectedOptions)}
                    options={loadTypes.map(type => ({ value: type, label: type }))}
                    classNamePrefix="select2-selection"
                    isLoading={!loadTypes.length}
                  />
                </div>

                {/* To Location Filter */}
                <div className="mb-3">
                  <Label>To Location</Label>
                  <Select
                    value={selectedFilters.to_location}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('to_location', selectedOptions)}
                    options={toLocations.map(location => ({ value: location, label: location }))}
                    classNamePrefix="select2-selection"
                    isLoading={!toLocations.length}
                  />
                </div>

                {/* LR Date Filter */}
                <div className="mb-3">
                  <Label>LR Date</Label>
                  <Select
                    value={selectedFilters.lr_date}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('lr_date', selectedOptions)}
                    options={lrDates.map(date => ({ value: date, label: date }))}
                    classNamePrefix="select2-selection"
                    isLoading={!lrDates.length}
                  />
                </div>

                {/* LHS Date Filter */}
                <div className="mb-3">
                  <Label>LHS Date</Label>
                  <Select
                    value={selectedFilters.lhs_date}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('lhs_date', selectedOptions)}
                    options={lhsDates.map(date => ({ value: date, label: date }))}
                    classNamePrefix="select2-selection"
                    isLoading={!lhsDates.length}
                  />
                </div>

                {/* Vehicle No Filter */}
                <div className="mb-3">
                  <Label>Vehicle No.</Label>
                  <Select
                    value={selectedFilters.vehicle_no}
                    isMulti
                    onChange={(selectedOptions) => handleFilterChange('vehicle_no', selectedOptions)}
                    options={vehicleNos.map(vehicle => ({ value: vehicle, label: vehicle }))}
                    classNamePrefix="select2-selection"
                    isLoading={!vehicleNos.length}
                  />
                </div>

                <div className="d-flex justify-content-around">
                  <button className="btn btn-secondary" onClick={clearFilters}>
                    Clear All Filters
                  </button>
                  <button className="btn btn-primary" onClick={applyFilters}>
                    Apply Filters
                  </button>
                </div>
              </div>
            </div>
          </SimpleBar>
        </div>
      </div>
      {
        isOpen && (
          <div
            className="rightbar-overlay"
            onClick={toggleFilterBar}
          />
        )
      }
    </div >
  );
};

InwardFilterBar.propTypes = {
  onApplyFilters: PropTypes.func.isRequired,
};

export default InwardFilterBar;
