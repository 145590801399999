import { 
    getInwardCommonFilter,
    getLHSPendingForVehichleArrival,
    getLHSPendingForVehichleArrivalHierarchical,
    getLHSPendingForVehichleUnloading,
    getLHSPendingForVehichleUnloadingHierarchical,
    getincomingConsignment,
    getincomingConsignmentHierarchical,
    getinwardSummary
} from "api";

export const getLHSPendingForVehichleArrivalHierarchicalReq = async (body) => {
    const response = await getLHSPendingForVehichleArrivalHierarchical(body);
    return response;
};
export const getLHSPendingForVehichleArrivalReq = async (body) => {
    const response = await getLHSPendingForVehichleArrival(body);
    return response;
};
export const getLHSPendingForVehichleUnloadingReq = async (body) => {
    const response = await getLHSPendingForVehichleUnloading(body);
    return response;
};
export const getLHSPendingForVehichleUnloadingHierarchicalReq = async (body) => {
    const response = await getLHSPendingForVehichleUnloadingHierarchical(body);
    return response;
};
export const getincomingConsignmentReq = async (body) => {
    const response = await getincomingConsignment(body);
    return response;
};
export const getincomingConsignmentHierarchicalReq = async (body) => {
    const response = await getincomingConsignmentHierarchical(body);
    return response;
};
export const getinwardSummaryReq = async (body) => {
    const response = await getinwardSummary(body);
    return response;
};
export const getInwardCommonFilterReq = async (body) => {
    const response = await getInwardCommonFilter(body);
    return response;
};
   