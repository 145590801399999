import { 
    getlrPendingForDispatchHierarchical,
    getlrPendingDispatch,
    getlrPending,
    getlrPendingForDoorChallenHierarchical,
    getbookingSummary,
    getbookingCommonFilter,
    getlrFreightBookingwise,
    getlrFreightBookingwiseHierarchical,
} from "api";

export const getlrPendingForDispatchHierarchicalReq = async (body) => {
    const response = await getlrPendingForDispatchHierarchical(body);
    return response;
};
export const getlrPendingDispatchReq = async (body) => {
    const response = await getlrPendingDispatch(body);
    return response;
};
export const getlrPendingReq = async (body) => {
    const response = await getlrPending(body);
    return response;
};
export const getlrPendingForDoorChallenHierarchicalReq = async (body) => {
    const response = await getlrPendingForDoorChallenHierarchical(body);
    return response;
};
export const getbookingSummaryReq = async (body) => {
    const response = await getbookingSummary(body);
    return response;
};
export const getbookingCommonFilterReq = async (body) => {
    const response = await getbookingCommonFilter(body);
    return response;
};
export const getlrFreightBookingwiseReq = async (body) => {
    const response = await getlrFreightBookingwise(body);
    return response;
};
export const getlrFreightBookingwiseHierarchicalReq = async (body) => {
    const response = await getlrFreightBookingwiseHierarchical(body);
    return response;
};
   