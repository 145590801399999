import React , {useEffect} from "react"
import { connect } from "react-redux";
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { Card,ButtonDropdown,DropdownToggle,DropdownMenu,DropdownItem,CardTitle,CardBody } from "reactstrap";
import { useState } from "react";
import { getcustomerOutstandingSummaryReq,getcustomerAgingAmountDifferenceReq,getcustomerAgingReq, getcustomerAgingBarReq, getcustomerExposureAnalysisReq,getagingPartyWiseReq, getcustomerAgingZoneReq, getPaymentTimeAanalysisReq, getCreditTimeAanalysisReq } from "./CreditControlService";
import DynamicTableCredit from "components/CustomComponents/CustomTable";
import EnhancedTableCredit from "components/CustomComponents/EnhancedTable";
import CustomAgGridTable from "components/CustomComponents/CustomAgGridTable";
// import DrillDownTable from "components/CustomComponents/DrillDownTable";
import CustomBarChart from "components/CustomComponents/CustomBarChart";
import ApexCharts from 'react-apexcharts';
import CreditFilterBar from './CreditFilterBar';
import DrillDownTable from "components/CustomComponents/DrillDownTable";
import AveragePaymentDaysChart from "components/CustomComponents/AveragePaymentDaysChart";
import AverageCreditDaysChart from "components/CustomComponents/AverageCreditDaysChart";
import SummaryCards from "components/CustomComponents/SummaryCards";
import BillingDrill from "components/CustomComponents/BillingDrill";

const CreditControl = (props) => {

  document.title = "Aurum | Credit Control";
  const [shareBtnToggle, setshareBtnToggle] = useState(false);
  const [dwnBtnToggle, setdwnBtnToggle] = useState(false);
  const [monthlyAnalysis, setMonthlyAnalysis] = useState([]);
  const [averagePaymentDays, setAveragePaymentDays] = useState(null);
  const [creditMonthlyAnalysis, setCreditMonthlyAnalysis] = useState([]);
  const [averageCreditDays, setAverageCreditDays] = useState(null);
  const [filters, setFilters] = useState({});
  
  const breadcrumbItems = [
    { title: "Home", link: "/dashboard" },
    { title: "Customer Outstanding", link: "/credit-control" },
  ]

  useEffect(() => {
    const fetchPaymentData = async () => {
      try {
        const paymentResponse = await getPaymentTimeAanalysisReq();
        const paymentData = await paymentResponse;
        setMonthlyAnalysis(paymentData?.results?.monthly_analysis);
        setAveragePaymentDays(paymentData?.results?.average_payment_days);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const fetchCreditData = async () => {
      try {
        const creditResponse = await getCreditTimeAanalysisReq();
        const creditData = await creditResponse;
        setCreditMonthlyAnalysis(creditData?.results?.monthly_credit_analysis);
        setAverageCreditDays(creditData?.results?.overall_avg_credit_time_days);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    
    fetchPaymentData();
    fetchCreditData();
  }, []);


  const sparklinePaymentData = monthlyAnalysis?.map(item => item.Avg_Payment_days);
  const sparklineCreditData = creditMonthlyAnalysis?.map(item => item.Avg_Credit_Time_Days);
  const sparklineOptionsCredit = {
    chart: {
      id: 'sparkline',
      type: 'area',
      height: 160,
      sparkline: {
        enabled: true
      }
    },
    stroke: {
      curve: 'smooth'
    },
    fill: {
      opacity: 1,
    },
    series: [{
      name: 'Credit Days',
      data: sparklineCreditData
    }],
    xaxis: {
      categories: creditMonthlyAnalysis?.map(item => item.month),
      labels: {
        show: false
      }
    },
    yaxis: {
      min: 0,
      show: false
    },
    colors: ['#008FFB'],
    title: {
      text: averageCreditDays ? averageCreditDays?.toFixed(2) : 'Loading...',
      offsetX: 30,
      style: {
        fontSize: '24px',
        cssClass: 'apexcharts-yaxis-title'
      }
    },
    subtitle: {
      text: 'Credit Period Analysis',
      offsetX: 30,
      style: {
        fontSize: '14px',
        cssClass: 'apexcharts-yaxis-title'
      }
    }
  };

  const sparklineOptionsPayment = {
    chart: {
      id: 'sparkline',
      type: 'area',
      height: 160,
      sparkline: {
        enabled: true
      }
    },
    stroke: {
      curve: 'smooth'
    },
    fill: {
      opacity: 1,
    },
    series: [{
      name: 'Payment Days',
      data: sparklinePaymentData
    }],
    xaxis: {
      categories: monthlyAnalysis?.map(item => item.month),
      labels: {
        show: false
      }
    },
    yaxis: {
      min: 0,
      show: false
    },
    colors: ['#008FFB'],
    title: {
      text: averagePaymentDays ? averagePaymentDays?.toFixed(2) : 'Loading...',
      offsetX: 30,
      style: {
        fontSize: '24px',
        cssClass: 'apexcharts-yaxis-title'
      }
    },
    subtitle: {
      text: 'Payment Time Analysis',
      offsetX: 30,
      style: {
        fontSize: '14px',
        cssClass: 'apexcharts-yaxis-title'
      }
    }
  };


  useEffect(() => {
    props.setBreadcrumbItems('Customer Outstanding' , breadcrumbItems);
  },)

  const downloadPDF = () => {}


const downloadExcel = () => {};

const applyFilters = (filters) => {
  // console.log("Applied Filters:", filters);
  setFilters(filters || {});
};
  
  return (
    <React.Fragment >
      <SummaryCards fetchDataFn={getcustomerOutstandingSummaryReq} />
      <CreditFilterBar onApplyFilters={applyFilters} />
      <div style={{ 
  display: 'flex', 
  flexDirection: 'column', 
  gap: '12px', // Gap between rows
  padding: 'px' 
}}>
  <div style={{ 
    display: 'flex', 
    flexWrap: 'wrap', 
    justifyContent: 'space-between', 
    gap: '12px' 
  }}>
    {/* Row 1 - Credit Period Analysis, Payment Time Analysis, Top 20 Customer Outstanding */}
    <Card style={{ flex: '1 1 calc(25% - 12px)', minWidth: '225px' }}>
      <ApexCharts
        options={sparklineOptionsCredit}
        series={sparklineOptionsCredit?.series}
        type="area"
        height={160}
        width="100%"
      />
      <AverageCreditDaysChart fetchDataFn={getCreditTimeAanalysisReq} />
    </Card>
    <Card style={{ flex: '1 1 calc(25% - 12px)', minWidth: '225px' }}>
      <ApexCharts
        options={sparklineOptionsPayment}
        series={sparklineOptionsPayment?.series}
        type="area"
        height={160}
        width="100%"
      />
      <AveragePaymentDaysChart fetchDataFn={getPaymentTimeAanalysisReq} />
    </Card>
    <Card body style={{ flex: '1 1 calc(50% - 12px)', minWidth: '300px' }} className="overflow-hidden">
      <CardTitle className="h4">Top 20 Customer Outstanding</CardTitle>
      <CustomBarChart fetchDataFn={getcustomerAgingBarReq} />
    </Card>
  </div>

  <div style={{ 
    display: 'flex', 
    flexWrap: 'wrap', 
    justifyContent: 'space-between', 
    gap: '12px' 
  }}>
    {/* Row 2 - Customer Exposure Analysis, Customer Difference Amount */}
    <Card body style={{ flex: '1 1 calc(40% - 12px)', minWidth: '300px' }} className="overflow-hidden">
      <CardTitle className="h4">Customer Exposure Analysis</CardTitle>
      <CustomAgGridTable fetchDataFn={getcustomerExposureAnalysisReq} customColumnDefs={customerExpoAnaColumnDefs}/>
    </Card>
    <Card body style={{ flex: '1 1 calc(60% - 12px)', minWidth: '300px' }} className="overflow-hidden">
      <CardTitle className="h4">Customer Difference Amount</CardTitle>
      <CustomAgGridTable fetchDataFn={getcustomerAgingAmountDifferenceReq} customColumnDefs={customerAgingAmountDifference}/>
    </Card>
  </div>

  <div style={{ 
    display: 'flex', 
    flexWrap: 'wrap', 
    justifyContent: 'space-between', 
    gap: '12px' 
  }}>
    {/* Row 3 - Customer Aging */}
    <Card body style={{ flex: '1 1 100%', minWidth: '300px' }} className="overflow-hidden">
      <CardTitle className="h4">Customer Aging</CardTitle>
      <CustomAgGridTable fetchDataFn={getcustomerAgingReq} customColumnDefs={custmerAgingColumnDefs}/>
    </Card>
  </div>

  <div style={{ 
    display: 'flex', 
    flexWrap: 'wrap', 
    justifyContent: 'space-between', 
    gap: '12px' 
  }}>
    {/* Row 4 - Location Wise Outstanding */}
    <Card body style={{ flex: '1 1 100%', minWidth: '300px' }} className="overflow-hidden">
      <CardTitle className="h4">Location Wise Outstanding</CardTitle>
      <BillingDrill
        uniqueKey="Location-Wise-Outstanding"
        fetchDataFn={getcustomerAgingZoneReq} 
        fetchDataAtBreadcrumbLimit={getagingPartyWiseReq}
        destinationPage={"/branch-details"}
        color="#D0E6F1"
      />
    </Card>
  </div>
</div>

    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(CreditControl);


// Custom column definitions
const customerExpoAnaColumnDefs = [
    { headerName: 'Party Name', field: 'party_name', sortable: true, filter: true,suppressMenu: true,width : 200, },
    { headerName: 'Credit Limit', field: 'credit_limit', sortable: true, filter: true,suppressMenu: true,width : 135, },
    { headerName: 'Excess Billing', field: 'excess_billing', sortable: true, filter: true,suppressMenu: true,width : 135,
        cellRenderer : (params) => {
            const value = params.value;
            const isNegative = value < 0;
            return (
            <div
            style={{
                color: isNegative ? 'red' : 'green', // Apply text color based on value
                padding: '5px', // Optional padding
                textAlign: 'right',
              }}
            >
                {params.value}
            </div>
            );
        },
        wrap:'nowrap'
     },
  ];

const custmerAgingColumnDefs = [
{ headerName: 'Customer Name', field: 'party_name', sortable: true, filter: true,suppressMenu: true,width : 200, },
{ headerName: 'Total Bill Amount', field: 'bill_amount', sortable: true, filter: true,suppressMenu: true,width : 160, },
{ headerName: 'Total Balance Amount', field: 'balance_amount', sortable: true, filter: true,suppressMenu: true,width : 190},
{ headerName: 'Total On Account Amount', field: 'on_account_amount', sortable: true, filter: true,suppressMenu: true,width : 220},
{ headerName: 'Total Due Amount', field: 'due_amount', sortable: true, filter: true,suppressMenu: true,width : 165},
{
  headerName: '0-30 Days',
  field: 'days_0_30',
  sortable: true,
  filter: true,
  suppressMenu: true,
  width: 120,
},
{
  headerName: '31-60 Days',
  field: 'days_31_60',
  sortable: true,
  filter: true,
  suppressMenu: true,
  width: 120,
},
{
  headerName: '61-90 Days',
  field: 'days_61_90',
  sortable: true,
  filter: true,
  suppressMenu: true,
  width: 120,
},
{
  headerName: '91-180 Days',
  field: 'days_91_180',
  sortable: true,
  filter: true,
  suppressMenu: true,
  width: 130,
},
  {
    headerName: '180+ Days',
    field: 'days_over_181',
    sortable: true,
    filter: true,
    suppressMenu: true,
    width: 120,
  },
];


const customerAgingAmountDifference = [
  { headerName: 'Customer Name', field: 'party_name', sortable: true, filter: true,suppressMenu: true,width : 220, },
  { headerName: 'Total Bill Amount', field: 'total_bill_amount', sortable: true, filter: true,suppressMenu: true,width : 160, },
  { headerName: 'Total Balance Amount', field: 'total_balance_amount', sortable: true, filter: true,suppressMenu: true,width : 190},
  { headerName: 'Remaining Amount', field: 'remaining_value', sortable: true, filter: true,suppressMenu: true,width : 180},
  ];