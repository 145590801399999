import React, { useEffect, useRef } from "react"
import { connect } from "react-redux";
import DrillDownTable from "components/CustomComponents/DrillDownTable";
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { Card, CardTitle } from "reactstrap";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import SummaryCards from "components/CustomComponents/SummaryCards";
import { getDeliverySummaryReq, getLhsPendingDDDFromLocationHierarchicalReq, getLhsPendingDDDFromLocationReq, getLhsPendingDDDToLocationHierarchicalReq, getLhsPendingDDDToLocationReq, getLhsPendingDDSettlementHierarchicalReq, getLhsPendingDDSettlementReq, getLhsPendingPreDDHierarchicalReq, getLhsPendingPreDDReq, getLrPendingForBillCumGatePassHierarchicalReq, getLrPendingForBillCumGatePassReq, getLrPendingForCashMemoGatePassHierarchicalReq, getLrPendingForCashMemoGatePassReq, getLrPendingForGatePassHierarchicalReq, getLrPendingForGatePassReq } from "./deliveryService";
import DeliveryFilterBar from "./DeliveryFilterBar";

const Delivery = (props) => {

  document.title = "Aurum | Delivery";
  const [shareBtnToggle, setshareBtnToggle] = useState(false);
  const [dwnBtnToggle, setdwnBtnToggle] = useState(false);
  const navigate = useNavigate();
  const tableRefs = useRef([]);
  const [filters, setFilters] = useState({});

  const breadcrumbItems = [
    { title: "Home", link: "/dashboard" },
    { title: "Delivery", link: "/delivery" },
  ]

  const handleCardTitleClick = (destinationPage) => (event) => {
    event.stopPropagation();
    navigate(destinationPage);
  };

  useEffect(() => {
    props.setBreadcrumbItems('Delivery', breadcrumbItems);
  },)

  const applyFilters = (filters) => {
    // console.log("Applied Filters:", filters);
    setFilters(filters || {});
  };


  const downloadPDF = () => { }


  const downloadExcel = () => {
  };

  return (
    <React.Fragment >
      <SummaryCards fetchDataFn={getDeliverySummaryReq} />
      {/* <div style={{ position: "relative" }}>
            <div
            style={{
                position: "absolute",
                top: -150,
                right: 10,
                display: "flex",
            }}
            >
              <ButtonDropdown
                isOpen={dwnBtnToggle}
                toggle={() => {
                  setdwnBtnToggle(!dwnBtnToggle);
                }}
              >
                <DropdownToggle caret className="btn btn-primary w-m mx-2">
                  <i className="fas fa-download me-2"></i> Download <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={downloadPDF}>
                    <i className="fas fa-file-pdf me-2"></i> PDF
                  </DropdownItem>
                  <DropdownItem onClick={downloadExcel}>
                    <i className="fas fa-file-excel me-2"></i> Excel
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
              <ButtonDropdown
                  isOpen={shareBtnToggle}
                  toggle={() => {
                      setshareBtnToggle(!shareBtnToggle)
                  }}
              >
                  <DropdownToggle caret className="btn btn-primary w-m mx-2 ">
                  <i className="fas fa-share me-2"></i> Share Via <i className="mdi mdi-chevron-down"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                      <DropdownItem>Email</DropdownItem>
                      <DropdownItem>Whatsapp</DropdownItem>
                  </DropdownMenu>
              </ButtonDropdown>
            </div>
        </div> */}
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '12px'
      }}>
        <DeliveryFilterBar onApplyFilters={applyFilters} filters={filters}/>
        <Card body style={{ flex: ' 1 1 calc(50% - 12px)', boxSizing: 'border-box', minHeight: '300px' }} className="overflow-hidden">
          <CardTitle onClick={handleCardTitleClick("lr-pending-for-direct-door-delivery-to-branch")} style={{ cursor: "pointer" }} className="h4">LHS pending for Direct Door Delivery (Basis to branch wise)</CardTitle>
          <DrillDownTable
            ref={el => tableRefs.current[1] = el}
            fetchDataFn={getLhsPendingDDDToLocationHierarchicalReq}
            fetchDataAtBreadcrumbLimit={getLhsPendingDDDToLocationReq}
            destinationPage={"lr-pending-for-direct-door-delivery-to-branch"}
            initialQueryParams={filters}
            color='#BFE0F5'
          />
        </Card>
        <Card body style={{ flex: ' 1 1 calc(50% - 12px)', boxSizing: 'border-box', minHeight: '300px' }} className="overflow-hidden">
          <CardTitle onClick={handleCardTitleClick("lr-pending-for-direct-door-delivery-from-branch")} style={{ cursor: "pointer" }} className="h4">LHS pending for Direct Door Delivery (Basis from branch wise)</CardTitle>
          <DrillDownTable
            ref={el => tableRefs.current[0] = el}
            fetchDataFn={getLhsPendingDDDFromLocationHierarchicalReq}
            fetchDataAtBreadcrumbLimit={getLhsPendingDDDFromLocationReq}
            destinationPage={"lr-pending-for-direct-door-delivery-from-branch"}
            initialQueryParams={filters}
            color='#FDECBF'
          />
        </Card>
        <div style={{ flex: '1 1 calc(50% - 12px)', boxSizing: 'border-box' }}>
          <Card body className="overflow-hidden" style={{ minHeight: '300px' }}>
            <CardTitle onClick={handleCardTitleClick("pre-door-delivery-sheet")} style={{ cursor: "pointer" }} className="h4">LRs Pending For Door Delivery (Pending for Pre-Door Delivery Sheet)</CardTitle>
            <DrillDownTable
              ref={el => tableRefs.current[2] = el}
              fetchDataFn={getLhsPendingPreDDHierarchicalReq}
              fetchDataAtBreadcrumbLimit={getLhsPendingPreDDReq}
              destinationPage={"pre-door-delivery-sheet"}
              initialQueryParams={filters}
              color='#D8F7C8'
            />
          </Card>
        </div>
        <div style={{ flex: '1 1 calc(50% - 12px)', boxSizing: 'border-box' }}>
          <Card body className="overflow-hidden" style={{ minHeight: '300px' }}>
            <CardTitle onClick={handleCardTitleClick("pdds-pending-for-door-delivery")} style={{ cursor: "pointer" }} className="h4">PDDS Pending For Door Delivery Settlement</CardTitle>
            <DrillDownTable
              ref={el => tableRefs.current[3] = el}
              fetchDataFn={getLhsPendingDDSettlementHierarchicalReq}
              fetchDataAtBreadcrumbLimit={getLhsPendingDDSettlementReq}
              destinationPage={"pdds-pending-for-door-delivery"}
              initialQueryParams={filters}
              color='#F6D1E5'
            />
          </Card>
        </div>
        <div style={{ flex: '1 1 calc(50% - 12px)', boxSizing: 'border-box' }}>
          <Card body className="overflow-hidden" style={{ minHeight: '300px' }}>
            <CardTitle onClick={handleCardTitleClick("lr-pending-for-gate-pass")} style={{ cursor: "pointer" }} className="h4">LRs Pending For Gate Pass (If Cash/Bank Memo or Bill generated is yes)</CardTitle>
            <DrillDownTable
              ref={el => tableRefs.current[1] = el}
              fetchDataFn={getLrPendingForCashMemoGatePassHierarchicalReq}
              fetchDataAtBreadcrumbLimit={getLrPendingForCashMemoGatePassReq}
              destinationPage={"lr-pending-for-gate-pass"}
              initialQueryParams={filters}
              color='#FFDED1'
            />
          </Card>
        </div>
        <div style={{ flex: '1 1 calc(50% - 12px)', boxSizing: 'border-box' }}>
          <Card body className="overflow-hidden" style={{ minHeight: '300px', }}>
            <CardTitle onClick={handleCardTitleClick("lr-pending-for-bank-memo-gate-pass")} style={{ cursor: "pointer" }} className="h4">LRs Pending For Cash/ Bank Memo cum Gate Pass</CardTitle>
            <DrillDownTable
              ref={el => tableRefs.current[4] = el}
              fetchDataFn={getLrPendingForBillCumGatePassHierarchicalReq}
              fetchDataAtBreadcrumbLimit={getLrPendingForBillCumGatePassReq}
              destinationPage={"lr-pending-for-bank-memo-gate-pass"}
              initialQueryParams={filters}
              color='#DFF6F0'
            />
          </Card>
        </div>
        <div style={{ flex: '1 1 calc(50% - 12px)', boxSizing: 'border-box' }}>
          <Card body className="overflow-hidden" style={{ minHeight: '300px', }}>
            <CardTitle onClick={handleCardTitleClick("lr-pending-for-bill-gate-pass")} style={{ cursor: "pointer" }} className="h4">LRs Pending For Bill cum Gate Pass</CardTitle>
            <DrillDownTable
              ref={el => tableRefs.current[4] = el}
              fetchDataFn={getLrPendingForGatePassHierarchicalReq}
              fetchDataAtBreadcrumbLimit={getLrPendingForGatePassReq}
              destinationPage={"lr-pending-for-bill-gate-pass"}
              initialQueryParams={filters}
              color='#C3E8F5'
            />
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Delivery);